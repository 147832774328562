import {ethers} from 'ethers';
import erc20abi from './erc20abi';

let provider: any;
let signer: any;

const chainIdToName: any = {
    1: 'mainnet',
    3: 'ropsten',
    4: 'rinkeby',
    5: 'goerli',
    42: 'kovan',
}

async function getProvider (returnSigner: boolean = false) {
    let result: any = {};

    // @ts-ignore
    const ethereum = window.ethereum;
    if (ethereum) {
        // const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        await ethereum.enable();
        result.provider = new ethers.providers.Web3Provider(ethereum);
        result.signer = result.provider.getSigner();
    }
    else result.provider = ethers.getDefaultProvider();

    return result;
}

const ethEtherscanLink = async (tx: string) => {
    let network;
    try {
        network = await provider.getNetwork();
    } catch(e) {
        console.warn(e);
        return '';
    }
    const networkName = network.chainId === 1 ? '' : chainIdToName[parseInt(network.chainId)];
    return `https://${networkName ? (networkName + '.') : ''}etherscan.io/tx/${tx}`
}

const ethCall = async (contractAddress: string, fabi: any, finputs: any, txObj: any) => {
    if (!fabi) return '';
    if (finputs === null) finputs = [];
    if (!(finputs instanceof Array)) finputs = [finputs];
    const isConstant = fabi.stateMutability === 'view' || fabi.stateMutability === 'pure';
    let _provider;
    if (isConstant) {
        if (!provider) provider = (await getProvider(false)).provider;
        _provider = provider;
    }
    else {
        if (!signer) {
            const result = await getProvider(true);
            provider = result.provider;
            signer = result.signer;
        }
        _provider = signer || provider;
    }

    const contract = new ethers.Contract(contractAddress, erc20abi, _provider);
    let value;
    try {
        value = await contract[fabi.name](...finputs);
    } catch (e) {
        console.error(e);
        return e.message;
    }

    if (!isConstant) value = value.wait();
    return value;
}

const extensions = {
    label: 'eth',
    items: [
        {
            label: 'call',
            value: ethCall,
        },
        {
            label: 'etherscan-link',
            value: ethEtherscanLink,
        }
    ]
}

function plugin ({transform}: {transform: Function}) {
    const er20functions = erc20abi.filter((item: any) => item.type === 'function');
    const options = transform(er20functions.map((func: any) => {
        func.label = func.name;
        return func;
    }));

    const marks = [
        {
            doctemplate: `# ERC20 Mark

<tokenAddress:(ui-input {"placeholder" "ens / 0x..." "label" "token address" "value" "dai.tokens.ethers.eth"})> <tokenFunction:(ui-select {"options" ${options}})>

<functionInputs:(if (nil? tokenFunction)
    nil
    (let* (
        abiInputs (get tokenFunction "inputs")
        stateMutability (get tokenFunction "stateMutability")
        isConstant (or (= stateMutability "pure") (= stateMutability "view"))
        noInputArgs (= (count abiInputs) 0)
    )
        (if (and isConstant noInputArgs)
            (ui-form abiInputs {"submitBtn" false})
            (ui-form abiInputs {"submitBtn" {"icon" "play"}})
        )
    ))
)>

<callResult:(ui-text (eth-call tokenAddress tokenFunction functionInputs ))>

<etherscanLink:(if
    (and
        (map? callResult)
        (contains? callResult "transactionHash")
    )
    (ui-link
        (eth-etherscan-link (get callResult "transactionHash"))
        {"icon" "proof"}
    )
    nil
)>`,
            imageIcon: 'function',
            command: `(message-send conversation  (str
                (if (and (map? callResult) (contains? callResult "transactionHash") )
                    (str
                        "<etherscanFinalLink:(ui-link "
                        (pr-str etherscanLink)
                        " "
                        (pr-str {"icon" "proof"})
                        " ))>"
                    )
                    ""
                )
                "My witness is #Ethereum that " tokenAddress "." (get tokenFunction "name") "(" (if (nil? functionInputs) "" functionInputs) ")" " is " "<finalResult:(ui-text " (pr-str callResult) " )>" ) )`,
        },
    ]

    const menuItem = {
        doctemplate: 'eth',
        imageIcon: '',
        items: marks,
    }

    return {
        menuItem,
        extensions,
    }
}

export default plugin;

