import React, {ChangeEvent, KeyboardEvent} from 'react';
import { Anchor } from 'antd';
import {PlayCircleOutlined} from '@ant-design/icons';
import UiSelect from '../ChatInput/ChatSelectInput';
import Modal from '../Modal/Modal';
import QRCode from 'qrcode';
import DateTime from '../DateTime/DateTime';
import CronBuild from '../DateTime/CronBuild';
import TimedQuestion from '../Question/TimedQuestion';
import Icon from '../Icon';
import JsonMessage from '../ChatMessage/JsonMessage';
import Markdown from './Markdown';
import {clipboardCopy} from '../../utils';
import commonStyles from '../../styles';

const { Link } = Anchor;

export const uiButton = (props: any) => {
    const {onCommand, data, mode} = props;

    const onClick = () => {
        onCommand(data.onClick, data.conversationId);
    }

    const _styles = commonStyles[mode].button || {};

    return <button className="DappButton" style={_styles} disabled={data.fromSelf} onClick={onClick}>{data.title}</button>
}

const uiText = (props: any) => {
    const {data} = props;
    const {value} = data;
    if (typeof value !== 'object') {
        return (<p>{value}</p>);
    }
    const mode = localStorage.getItem('arkmode');
    return <JsonMessage mode={mode}>{value}</JsonMessage>
}

const uiJson = (props: any) => {
    const {data} = props;
    let {value} = data;
    if (typeof value !== 'object') {
        value = {result: value};
    }
    const mode = localStorage.getItem('arkmode');
    return <JsonMessage mode={mode}>{value}</JsonMessage>
}

const uiLink = (props: any) => {
    const {data} = props;
    const {value, label, icon} = data;
    if (typeof value !== 'string') return <span></span>;
    if (!icon) {
        return (
            <Anchor>
                <Link href={value} target="_blank" title={label || value}></Link>
            </Anchor>
        )
    }
    const onClick = () => window.open(value, '_blank');
    return (
        <Icon style={{marginLeft: 5, marginRight: 5}} type={icon} onClick={onClick}/>
    )
}

const uiIcon = (props: any) => {
    const {data} = props;
    return <Icon type={data.text}/>
}

const inputStyles = {
    inputStyle: {
      border: 'none',
      borderTopWidth: '1',
      borderTopStyle: 'solid',
      borderTopColor: '#ddd',
      fontSize: '16',
      outline: 'none',
      padding: '30',
      width: '100%',
    } as React.CSSProperties,
};

interface ChatInputProps {
    id: any;
    mode: string;
    styles: object;
    data: any;
    value: string;
    ref: any;
    onSelected: (msg: any) => void;
}

export const ChatInput = React.memo((props: ChatInputProps) => {
    const { mode, data, value = '', styles = {}, onSelected } = props;

    const [currentValue, setValue] = React.useState(value || data.value);

    // const debouncedOnSelected = React.useCallback(debounce((_value: any) => {
    //     console.log('---debouncedOnSelected', _value, currentValue);
    //     onSelected(_value);
    // }, 2000), []);

    const _onChange = (ev: ChangeEvent<HTMLInputElement>) => {
        const value = ev?.target?.value || '';
        setValue(value);
        // debouncedOnSelected(value);
    }

    const onFinish = () => {
        onSelected(currentValue);
    }

    const handleKeypress = (ev: KeyboardEvent) => {
        if (!ev.shiftKey && ev.key === 'Enter') {
            onFinish();
        }
    }

    const __styles = commonStyles[mode] ? commonStyles[mode].common : {};
    const _styles = {...(styles || inputStyles.inputStyle), ...__styles}

    return (
        <input value={currentValue} className="input-medium" type="text" style={{..._styles}} placeholder={data.placeholder} onChange={_onChange} onBlur={onFinish} onKeyPress={handleKeypress}/>
    );
}, (prevProps: any, nextProps: any) => {
    if (prevProps.value !== nextProps.value) return false;
    if (prevProps.data.placeholder !== nextProps.data.placeholder) return false;
    return true;
});

export const UiTextarea = React.memo((props: any) => {
    const { mode, data, value = '', styles = {}, onSelected } = props;

    const [currentValue, setValue] = React.useState(value || data.value);

    const _onChange = (ev: any) => {
        const value = ev?.target?.value || '';
        setValue(value);
    }

    const onFinish = () => {
        onSelected(currentValue);
    }

    const handleKeypress = (ev: KeyboardEvent) => {
        console.log(ev.shiftKey)
        if (!ev.shiftKey && ev.key === 'Enter') {
            onFinish();
        }
    }

    const __styles = commonStyles[mode] ? commonStyles[mode].common : {};
    const _styles = {...(styles || inputStyles.inputStyle), ...__styles}
    const finalStyles = {
        width: '100%', height: 100,
        ..._styles,
        ...(data.style ||{})
    }

    return (
        <textarea value={currentValue} className="input-medium" style={finalStyles} placeholder={data.placeholder} onChange={_onChange} onBlur={onFinish} onKeyPress={handleKeypress}/>
    );
}, (prevProps: any, nextProps: any) => {
    if (prevProps.value !== nextProps.value) return false;
    if (prevProps.data.placeholder !== nextProps.data.placeholder) return false;
    return true;
});

export class UiForm extends React.Component<any, any> {
    constructor (props: any) {
        super(props);

        const {data, value} = props;
        let inivalue = value || data.value || new Array(data.items.length);
        if (inivalue.length !== data.items.length) {
            inivalue = new Array(data.items.length);
        }

        this.state = {
            values: inivalue,
        }
    }

    componentDidMount () {
        this.setUpstream();
    }

    componentDidUpdate (prevProps: any, prevState: any) {
        this.setUpstream();
    }

    setUpstream () {
        const {data, onSelected, value} = this.props;
        const {items} = data;

        const hasChanged = items && value && items.length !== value.length;
        const isEmpty = !value || value.length === 0;
        console.log('hasChanged, isEmpty', hasChanged, !isEmpty);
        // Change values upstream
        if (hasChanged && !isEmpty) {
            onSelected([]);
        }
    }

    render () {
        const {data, onSelected, mode} = this.props;
        const {items, submitBtn} = data;
        const {values} = this.state;

        const onCommit = () => {
            onSelected(values);
        }

        const components = items.map((item: any, i: number) => {

            const handleSubmit = (value: any) => {
                const {values} = this.state;
                values[i] = value;
                this.setState({values});
                if (!submitBtn) {
                    onSelected(values);
                }
            }

            let _value = values[i];

            const Component = componentMap[item.type];
            const key = i;
            return <Component key={key} mode={mode} id={key} data={item.data} value={_value} onSelected={handleSubmit} />
        });

        let btn = (<></>);
        if (submitBtn) {
            if (submitBtn.icon) {
                btn = <Icon style={{marginLeft: 10, marginRight: 10}} type={submitBtn.icon} onClick={onCommit}/>
            }
            else {
                btn = <PlayCircleOutlined className="ActionButton" onClick={onCommit} />;
            }
        }

        return (
            <div>
                {components}
                {btn}
            </div>
        );
    }
}
var qropts = {
    color: {
      dark:"#000",
      light:"#aaa"
    }
}

const Qrcode = (props: any) => {
    const {data} = props;
    const {url} = data;
    const ref = React.createRef<HTMLCanvasElement>();

    React.useEffect(() => {
        if (!ref.current) return;
        const canvas = ref.current;
        QRCode.toCanvas(canvas, url, qropts, function (error: Error) {
            if (error) console.error(error)
            // @ts-ignore
            canvas.style.height = '200px';
            // @ts-ignore
            canvas.style.width = '200px';
        });
    });

    const onClick = () => clipboardCopy(url);

    return (
        <canvas ref={ref} onClick={onClick} style={{height: '200px', width: '200px'}}></canvas>
    )
}

const uiMarkdown = (props: any) => {
    const {data, mode, execute} = props;
    const {label} = data;
    console.log('label', label)

    return <Markdown mode={mode} execute={execute}>{label || ''}</Markdown>;
}

const componentMap: any = {
    'button': uiButton,
    'text': uiText,
    'json': uiJson,
    'link': uiLink,
    'icon': uiIcon,
    'input': ChatInput,
    'textarea': UiTextarea,
    'select': UiSelect,
    'form': UiForm,
    'modal': Modal,
    'qrcode': Qrcode,
    'markdown': uiMarkdown,
    'datetime': DateTime,
    'cronbuild': CronBuild,
    'timedquestion': TimedQuestion,
}

export default componentMap;
