export const EMPTY = {
    doctemplate: "",
    imageIcon: '',
    command: ``
}

export const ADD_NODE = ({
    menutype,
    selectedLevel,
    doctemplate,
    command,
    imageIcon,
}: {
    menutype?: any,
    selectedLevel?: any,
    doctemplate?: any,
    command?: any,
    imageIcon?: any,
}) => {
    return {
    imageIcon: 'plus',
    doctemplate: `# Add menu item

<itemtype:(ui-select {"options" {"leaf" "leaf" "level" "level" } "value" "leaf"})> in ${
    menutype ||
    '<menutype:(ui-select {"options" {"system" "system" "conversation" "conversation" "message" "message"} "value" "conversation"})>'
} menu <selectedLevel:(ui-select {"options" (menu-items ${menutype ? `"${menutype}"` : 'menutype'} "levels") ${selectedLevel ? `"value" ${selectedLevel}` : ''} })> after <selectedLeaf:(ui-select {"options" (menu-items ${menutype ? `"${menutype}"` : 'menutype'} "leafs" selectedLevel ) })>

with icon <imageIcon:(ui-select-icon nil ${imageIcon || ''})>, description:

<composeDoctemplate:(ui-textarea {"placeholder" "markdown template" "value" ${doctemplate || '"# My mark"'} "style" {"width" "49%"} })> <composeCommand:(ui-textarea {"placeholder" "taylor command" "value" ${command || '"!(log (theme-get))"'} "style" {"width" "49%"} })>

## Mark Preview

<uiEval:(ui-eval composeDoctemplate )>

`,
    command: `(menuset
        ${menutype ? `(quote ${menutype})` : `menutype`}
        itemtype
        selectedLevel
        selectedLeaf
        composeDoctemplate
        imageIcon
        composeCommand
    )`,
}}

export const RESET_MENU = {
    doctemplate: "reset menu to default",
    imageIcon: 'function',
    command: `!(menu-reset )`
}

export const RESET_ACCOUNT = {
    doctemplate: "reset account",
    imageIcon: 'function',
    command: `!(identity-reset)`,
}

export const QUESTION = {
    doctemplate: `# Ask a question

Type <type:(ui-select {"options" {"yes/no" "yes/no" "choice" "choice"} "value" "yes/no"})>, require answer <requireAnswer:(ui-select {"options" {"yes" "yes" "no" "no" "timed" "timed"} "value" "yes"})>

Content: <content:(ui-input {"placeholder" "Are you human?"})> `,
    imageIcon: 'function',
    // "multiple choice" "mchoice" "extended choice" "echoice" "extended mutliple choice" "emchoice" "essay" "essay" "value" "value" "dropdown" "dropdown"
    command: `(message-send conversation  (mquote (ui-question "root.questions.@your.question1" type content requireAnswer ) ) )`,
}

export const QUESTION_CRON = {
    doctemplate: `Ask a timed question of type |type:(ui-select {"options" {"yes/no" "yes/no" "choice" "choice"} "value" "yes/no"})|: |text:(ui-input {"placeholder" "Are you human?"})|. Answer in |interval:(ui-input {"placeholder" "1"})| |unit:(ui-select {"options" {"seconds" "seconds" "minutes" "minutes" "hours" "hours"} "value" "minutes"})|. Store answer in |store:(ui-input {"placeholder" "alias"})|`,
    imageIcon: 'function',
    command: `(message-send conversation "(ui-question-cron {
        (quote store) (quote root.questions.|store|)
        (quote type) (quote |type| )
        (quote text) (quote |text| )
        (quote interval) (quote |interval| )
        (quote unit) (quote |unit| )
    })" )`,
}

export const CRONJOB = {
    doctemplate: `Cronjob: at time |datetime:(ui-select-datetime {})|

do |command:(ui-input {"placeholder" "(say hello)"})|.

Repeat |times:(ui-select {"options" {"1" "1" "2" "2" "3" "3" "4" "4" "5" "5" "unlimited" "unlimited"} "value" "yes/no"})| times at period: |period:(ui-select {"options" {"hourly" "hourly" "daily" "daily" "weekly" "weekly" "biweekly" "biweekly" "monthly" "monthly" "bimonthly" "bimonthly" "yearly" "yearly" } })|`,
imageIcon: 'function',
command: `(cron (quote |command| ) (quote |datetime| ) (quote |times| ) (quote |period|) )`,
}

export const ADD_CONTACT = {
    imageIcon: 'qrcode',
    doctemplate: `# initiate connect

Ask your friend to scan the QR code or click on the QR code to copy the connection link
(each QR code is crafted for 1 friend; do not reuse)

<qrcode:(ui-qrcode (arklink "connect"))>
`,
    command: ``
}

export const LINK_DEVICE = {
    imageIcon: 'qrcode',
    doctemplate: `# link device

Scan this QR code with your primary identity or click on the QR code to copy the connection link

<qrcode:(ui-qrcode (arklink "link"))>
`,
    command: ``,
}

export const IDENTITY_CREATE = {
    doctemplate: "create identity",
    imageIcon: 'plus',
    command: `!(identity-new)`
}

export const IDENTITY_SELECT = {
    imageIcon: 'user',
    doctemplate: `# Select identity

<identitySelection:(ui-select {"options" (identity-get-all )} )>`,
    command: `(identity-activate identitySelection)`
}

export const REPLY_MESSAGE = {
    imageIcon: 'message',
    doctemplate: `# Reply to message

<originalMessage:(ui-text (get (get message "data") "body") )>

with icon <imageIcon:(ui-select-icon "reply" "like")>

<replyMessage:(ui-input {"placeholder" "reply..."})>
`,
    command: `(message-reply
        message
        replyMessage
        imageIcon
    )`,
}

export const THEME_CHANGE = {
    doctemplate: `# switch theme

<selectedTheme:(ui-select {"options" {"dark" "dark" "light" "light" "system" "system"} "value" (theme-get )})>

`,
    imageIcon: 'setting',
    command: `(theme-switch selectedTheme)`
}
