import JsonMessage from './JsonMessage';
import Markdown from '../markdown/Markdown';
import {CheckCircleOutlined} from '@ant-design/icons';
import {chat as chatUtils} from '@ark-us/chat-manager';
import {itemLabel} from '../../utils';
const {Message: ChatMessage} = chatUtils;

export interface ExecutedMessageProps {
    message: typeof ChatMessage;
    children: any;
    mode: string;
}

export default function ExecutedMessage (props: ExecutedMessageProps) {
    const {children, message, mode} = props;

    const {state} = message.data;
    const body = message.data.message.data.body;
    const isFromSelf = message.isFromSelf && message.isFromSelfDevice;

    const actionButton = isFromSelf
        // ? (<ButtonIcon size={40} type="rollback" onClick={() => message.onClick('rollback')} />)
        ? <span></span>
        : (
            <CheckCircleOutlined className="ActionButton" style={{position: 'absolute', marginLeft: 40}} onClick={() => message.onClick('apply', state.mutated)} />
        )

    return (
        <div>
            {children ? <Markdown mode={mode}>{children}</Markdown> : <span></span>}
            <div>
                <span>{message.sender.id} has executed </span>
                <span style={{fontStyle: 'italic'}}>{itemLabel(body, 300)}. </span>
                <span>Result: </span>
                <JsonMessage mode={mode}>{state.result}</JsonMessage>
                <span>Mutated: </span>
                <JsonMessage mode={mode}>{state.mutated}</JsonMessage>
                {state.errors
                    ? <div><span>Errors: </span><JsonMessage mode={mode}>{state.errors}</JsonMessage></div>
                    : <span></span>
                }
            </div>
            {actionButton}
        </div>
    )
}
