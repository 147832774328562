import React from 'react';

export interface AvatarProps {
  path: string,
  color: string,
  complement: string,
  size: number,
}

const Avatar = (props: AvatarProps) => {
  const { path, color, complement, size = 51 } = props;

  return (
    <svg width={size} height={size}  viewBox="0 0 1024 1024">
        <g className="layer">
        <title>Layer 1</title>
        <ellipse cx="512" cy="512" fill={complement} id="svg_1" opacity="0.2" rx="440" ry="440" stroke="#000000"/>
        </g>
        <g className="layer">
        <title>Layer 2</title>
        <path d={path} fillRule="evenodd" fill={color} strokeWidth="0" id="svg_2" opacity="0.8" stroke="#000000"/>
        </g>
    </svg>
  );
};

export default Avatar;
