import Markdown from '../markdown/Markdown';
import {PlayCircleOutlined} from '@ant-design/icons';
import {chat as chatUtils} from '@ark-us/chat-manager';
import {itemLabel} from '../../utils'
const {Message: ChatMessage} = chatUtils;

export interface ExecutableMessageProps {
    mode: any;
    message: typeof ChatMessage;
    children: any;
}

export default function ExecutableMessage (props: ExecutableMessageProps) {
    const {children, message, mode} = props;
    const onClick = () => {
        message.onClick('execute', message);
    }
    return (
        <div>
            <Markdown mode={mode}>{itemLabel(children, 400)}</Markdown>
            <PlayCircleOutlined className="ActionButton" style={{position: 'absolute', marginLeft: 40}} onClick={onClick}/>
        </div>
    )
}
