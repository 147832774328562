import { DateTime, DurationObject } from 'luxon';
import {chat as chatUtils} from '@ark-us/chat-manager';
import {ButtonIcon} from '../Buttons.js';
const {MessageStatus, getTimestamp} = chatUtils;

// sending - 1 empty circle
// sent - 1 empty checkmark circle
// received - 2 empty checkmark circles
// seen - 2 full checkmark circles
export const getStatusIcons = (status: any, size: number = 13) => {
    let statusIcons;
    switch (status) {
        case MessageStatus.sending:
            statusIcons = (<ButtonIcon size={size} type="sending" onClick={() => {}} />);
            break;
        case MessageStatus.sent:
            statusIcons = (<ButtonIcon size={size} type="check" onClick={() => {}} />);
            break;
        case MessageStatus.received:
            statusIcons = (<div>
                <ButtonIcon size={size} type="check" onClick={() => {}} />
                <ButtonIcon size={size} type="check" onClick={() => {}} />
            </div>);
            break;
        case MessageStatus.seen:
            statusIcons = (<div>
                <ButtonIcon size={size} type="check-filled" onClick={() => {}} />
                <ButtonIcon size={size} type="check-filled" onClick={() => {}} />
            </div>);
            break;
        default:
            statusIcons = <div></div>;
    }
    return statusIcons;
}

export const formatDate = (_timestamp: any) => {
    let date: string = '';
    const timestamp = getTimestamp(_timestamp);
    const mdate = DateTime.fromMillis(timestamp);
    const difference: DurationObject = mdate.diff(
        DateTime.local(),
        ['years', 'days', 'hours', 'minutes']
    ).toObject();

    if (mdate > DateTime.local()) {
        date = '0s';
    }
    else if (difference.years && difference.years < 0) {
        date = mdate.toLocaleString(DateTime.DATE_MED);
    }
    else if (difference.days && difference.days < -7) {
        date = mdate.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY).split(',')[0]
    }
    else if (difference.days && difference.days < -0.5) {
        date = mdate.toRelative({ unit: "days" }) || '';
    }
    else if (difference.hours && difference.hours < -0.5) {
        date = mdate.toRelative({ unit: "hours" }) || '';
    }
    else if (difference.minutes && difference.minutes < -0.5) {
        date = mdate.toRelative({ unit: "minutes" }) || '';
    }
    else {
        date = mdate.toRelative({ unit: "seconds" }) || '';
    }

    date = date.replace(' hours ago', 'h');
    date = date.replace(' hour ago', 'h');
    date = date.replace(' minutes ago', 'm');
    date = date.replace(' minute ago', 'm');
    date = date.replace(' seconds ago', 's');
    date = date.replace(' second ago', 's');
    date = date.replace(' days ago', 'd');
    date = date.replace(' day ago', 'd');
    date = date.replace(' ago', '');
    return date;
}
