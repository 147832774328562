import React from 'react';
import { Message } from 'react-chat-ui';
import Markdown from '../markdown/Markdown';
import DappMarkdown from '../markdown/DappMarkdown';
import ChatBubble from '../ChatBubble/ChatBubble';
import JsonMessage from './JsonMessage';
import ExecutableMessage from './ExecutableMessage';
import ExecutedMessage from './ExecutedMessage';
import SystemMessages from './SystemMessages';
import {TypingMessage, NoTypingMessage} from './TypingMessages';
import {theme} from './styles';
import {getStatusIcons, formatDate} from './utils';
import {MoreOutlined} from '@ant-design/icons';
import {chat as chatUtils} from '@ark-us/chat-manager';
import './ChatMessage.css';
const {Message: ChatMessage} = chatUtils;

export interface CustomChatBubbleProps {
    mode: string;
    message: typeof ChatMessage;
}

function areEqual(prevProps: any, nextProps: any) {
    if (prevProps.mode !== nextProps.mode) return false;
    if (prevProps.message.id !== nextProps.message.id) return false;
    if (prevProps.message.conversationId !== nextProps.message.conversationId) return false;
    return true;
}

export default  React.memo(function CustomChatBubble (props: any) {
    const {message: _message, mode} = props;
    const _mode = mode || 'light';


    const _theme: any = theme[_mode];
    if (_message.type === 'typing') {
        return <TypingMessage message={_message} />
    }
    if (_message.type === 'notyping') {
        return <NoTypingMessage message={_message} />
    }

    const {textComponent, bubblesCentered, bubbleStyles} = getTextComponent(_message, _mode, _theme);

    const message = new Message({
        id: _message.user,
        message: _message.data.body, // execute doesn't have data
    });

    const {timestamp} = _message;
    const {status} = _message.data || {};

    let footerClass;
    let menuClass;
    let footer;
    let date = formatDate(timestamp);
    let statusIcons = getStatusIcons(status, 13);
    let messageStyles = JSON.parse(JSON.stringify(_theme.CLASSIC));
    messageStyles.chatbubble.backgroundColor = message.id === 0 ? _theme.BubbleBack0 : _theme.BubbleBack1;

    const menubtn = _message.onMenu ? (
        <MoreOutlined
            className="ChatMessageIcon"
            onClick={_message.onMenu}
        />
    ) : <></>;

    if (message.id === 1) {
        messageStyles.chatbubble.borderBottomLeftRadius = 1;
        footerClass = 'ChatBubbleFooterLeft';
        menuClass = 'ChatBubbleFooterMenuRight';
        footer = (
            <div className="ChatBubbleFooterContainer">
                <div className={"ChatBubbleFooter " + footerClass}>
                    <p className="ChatTimestamp">{date}</p>
                    {statusIcons}
                </div>
                <div className={"ChatBubbleFooterMenu " + menuClass}>
                    {menubtn}
                </div>
            </div>
        )
    }
    else {
        messageStyles.chatbubble.borderBottomRightRadius = 1;
        footerClass = 'ChatBubbleFooterRight';
        menuClass = 'ChatBubbleFooterMenuLeft';
        footer = (
            <div className="ChatBubbleFooterContainer">
                <div className={"ChatBubbleFooterMenu " + menuClass}>
                    {menubtn}
                </div>
                <div className={"ChatBubbleFooter " + footerClass}>
                    <p className="ChatTimestamp">{date}</p>
                    {statusIcons}
                </div>
            </div>
        )
    }

    const usedStyles = bubbleStyles || messageStyles;
    usedStyles.chatbubble.maxWidth = '90%';

    if (_message.data.replyTarget && _message.data.replyTarget.data) {
        const _reply = (_message.data.replyTarget.data.body || '').split('\n').map((str: string) => str ? ('*' + str + '*') : '').join('\n');

        let tags = _message.data.tags || [];
        if (!(tags instanceof Array)) tags = [tags];
        tags = tags.map((icon: string) => {
            return `<icon${icon}:(ui-icon "${icon}")>`
        }).join('');

        message.message = `

${_reply}

${tags}

${message.message}
`
    }

    return (
        <div>
            <ChatBubble
                message={message}
                bubbleStyles={usedStyles}
                bubblesCentered={bubblesCentered}
                TextComponent={textComponent}
            >
                {footer}
            </ChatBubble>
            {_message.data.result ? <JsonMessage mode={_mode}>{_message.data.result}</JsonMessage> : <span></span>}
        </div>
    );
}, areEqual);


function getTextComponent (_message: any, _mode: string, _theme: any) {
    let bubbleStyles;
    let bubblesCentered = false;
    let textComponent: any = Markdown;

    if (_message.eeExecution) {
        textComponent = (_props: any) => {
            const {children} = _props;
            return <Markdown mode={_mode} execute={_message.eeExecution}>{children}</Markdown>;
        }
    }

    if (_message.executable) {
        textComponent = (_props: any) => {
            const {children} = _props;
            return <ExecutableMessage mode={_mode} message={_message}>{children}</ExecutableMessage>;
        }
    }
    if (_message.type === 'execute') {
        textComponent = (_props: any) => {
            const {children} = _props;
            return <ExecutedMessage mode={_mode} message={_message}>{children}</ExecutedMessage>
        }
        bubbleStyles = _theme.SYSTEM_BUBBLE_STYLE
        bubblesCentered = true;
    }
    if (_message.type === 'system') {
        textComponent = (_props: any) => {
            const {children} = _props;
            return <SystemMessages mode={_mode} message={_message}>{children}</SystemMessages>
        }
        bubbleStyles = _theme.SYSTEM_BUBBLE_STYLE
        bubblesCentered = true;
    }
    if (_message.data?.view) {
        textComponent = (_props: any) => {
            return (
                <DappMarkdown mode={_mode} data={{..._message}}>
                    {_message.data.view?.items}
                </DappMarkdown>
            )
        }
        bubbleStyles = _theme.SYSTEM_BUBBLE_STYLE
        bubblesCentered = true;
    }
    return {textComponent, bubblesCentered, bubbleStyles};
}
