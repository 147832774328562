import JsonMessage from './JsonMessage';
import {chat as chatUtils} from '@ark-us/chat-manager';
import {itemLabel} from '../../utils';
const {Message: ChatMessage} = chatUtils;

export interface SystemMessagesProps {
    message: typeof ChatMessage;
    children: any;
    mode: string;
}

export default function SystemMessages (props: SystemMessagesProps) {
    const {children, mode} = props;
    const components = children.map((item: any, i: number) => {
        if (typeof item === 'string') return <span key={i}>{itemLabel(item, 300)}</span>;
        return <JsonMessage mode={mode} key={i}>{item}</JsonMessage>
    })

    return (
        <div>
            {components}
        </div>
    )
}
