import * as React from 'react';
import ChatBubbleProps from './interface';
import styles from './styles';

const defaultBubbleStyles = {
  userBubble: {},
  chatbubble: {},
  text: {},
};

export default class ChatBubble extends React.Component<ChatBubbleProps> {
  public render() {
    const { bubblesCentered, children, TextComponent } = this.props;
    let { bubbleStyles } = this.props;
    bubbleStyles = bubbleStyles || defaultBubbleStyles;
    const { userBubble, chatbubble, text } = bubbleStyles;

    // message.id 0 is reserved for blue
    const chatBubbleStyles: any =
      this.props.message.id === 0
        ? {
            ...styles.chatbubble,
            ...bubblesCentered ? {} : styles.chatbubbleOrientationNormal,
            ...chatbubble,
            ...userBubble,
          }
        : {
            ...styles.chatbubble,
            ...styles.recipientChatbubble,
            ...bubblesCentered
              ? {}
              : styles.recipientChatbubbleOrientationNormal,
            ...userBubble,
            ...chatbubble,
          };
    const chatBubbleText: any = {...styles.p, ...text};
    const bubbleText = TextComponent ? <TextComponent>{this.props.message.message}</TextComponent> : <p style={chatBubbleText}>{this.props.message.message}</p>

    return (
      <div className="ChatBubbleContainer"
        style={{
          ...styles.chatbubbleWrapper,
        }}
      >
        <div className="ChatBubbleTextContainer" style={chatBubbleStyles}>
          {bubbleText}
          {children}
        </div>
      </div>
    );
  }
}

export type {ChatBubbleProps};
