import React from 'react';
import { Menu} from 'antd';
import Icon from '../Icon';
import { MultilevelNodes } from './interfaces';
import MenuHandler from './persistence';
import {
    defaultMenu,
    systemMenu,
    messageMenu,
    hydrateMenu,
    getItemAtIndex,
    flattenMenu,
    menuset,
    itemLabel,
} from './utils';
import './ChatMenu.css';

const { SubMenu } = Menu;

export interface ChatMenuProps {
    mode: string;
    id: string;
    conversationId: string;
    menuItems?: MultilevelNodes[],
    selectCallback: (command: any, doctemplate: string) => void,
    selectedLabel: (...args: any) => any,
    selectedListItem: (...args: any) => any,
    chatManager?: any,
}

export default class ChatMenu extends React.Component<ChatMenuProps, any> {
    eeExecution: any;
    sendMessage: Function;

    constructor(props: ChatMenuProps) {
        super(props);
        const {chatManager, conversationId} = props;
        let menuItems: MultilevelNodes[] = getAndUpdateMenuItems(props.id, props.menuItems);
        const conv = (
            chatManager._conversations[conversationId]
            ? chatManager._conversations[conversationId]
            : chatManager._conversations.self
        );
        this.eeExecution = {
            execute: (message: any) => {
                return conv.ee.eval(message, chatManager.uiExecutionHandlers);
            },
            transform: (jsObj: any) => conv.toEE(jsObj),
        }
        this.sendMessage = (message: any) => {
            chatManager.sendMessage(conv.conversation, message);
        }

        hydrateMenu(props.id, props.selectCallback, menuItems, (newitems: MultilevelNodes) => {
            this.setState({menuItems: newitems});
        }, this.eeExecution, this.sendMessage, this.props.conversationId);

        this.state = {
            menuItems,
        }
    }

    componentDidMount () {
        const {chatManager, selectCallback} = this.props;
        if (!chatManager) return;

        const menureset = () => {
            MenuHandler.removeAll();
        }
        chatManager.extendEE('menu-reset', menureset);

        const _getMenuItems = (category: string, itemType?: string, levelObject?: any) => {
            const levels = !itemType || itemType === 'levels';
            const leafs = !itemType || itemType === 'leafs';

            let menuItems;
            if (category === this.props.id) {
                menuItems = this.state.menuItems;
            }
            else {
                menuItems = getMenuItems(category);
            }

            if (levelObject) {
                const indexes = [...levelObject.indexes];
                let items = [];
                if (indexes.length === 0) {
                    items = menuItems;
                }
                else {
                    const obj = getItemAtIndex({
                        items: menuItems
                    }, indexes);
                    items = obj.items;

                }
                return items.map((item: any) => {
                    return {label: itemLabel(item.doctemplate), indexes: item.indexes};
                });
            }

            const items = flattenMenu({
                doctemplate: '.',
                items: menuItems,
            }, [], levels, leafs, '', true);
            return items.map((item: any) => {
                return {label: itemLabel(item.doctemplate), indexes: item.indexes};
            });
        }
        chatManager.extendEE('menu-items', _getMenuItems);

        const _menuset = (
            category: string,
            nodetype: string,
            level: any,
            afterLeaf: any,
            doctemplate: string,
            imageIcon: string,
            command?: string,
        ) => {
            console.debug(`----menuset`, category, nodetype, level, afterLeaf, doctemplate, command);

            let _menuItems: MultilevelNodes[];
            let onChange = (...args: any) => {};
            if (category === this.props.id) {
                _menuItems = this.state.menuItems;
                onChange = (newitems: MultilevelNodes) => {
                    this.setState({menuItems: newitems});
                }
            }
            else {
                _menuItems = getMenuItems(category);
            }

            const menuItems = menuset(
                selectCallback,
                onChange,
                this.eeExecution,
                this.sendMessage,
                this.props.conversationId,
                _menuItems,
                category,
                nodetype,
                level,
                afterLeaf,
                doctemplate,
                imageIcon,
                command,
            );

            MenuHandler.set(category, menuItems);

            if (category === this.props.id) {
                this.setState(menuItems);
            }
        }
        chatManager.extendEE('menuset', _menuset);
    }

    render () {
        const {menuItems} = this.state;

        const components = menuItems.map((item: MultilevelNodes, i: number) => recursiveMenuBuild(item, [i]));

        return (
              <Menu
                className="ChatMenu th-border"
                mode="inline"
              >
               {components}
              </Menu>
          );
    }
}

function getMenuItems (id: string) {
    let menuItems: MultilevelNodes[] = MenuHandler.get(id);
    if (menuItems.length === 0) {
        if (id === 'system') menuItems = systemMenu();
        else if (id === 'message') menuItems = messageMenu();
        else menuItems = defaultMenu();
    }
    return menuItems;
}

function getAndUpdateMenuItems (id: string, additionalNodes: MultilevelNodes[] = []) {
    const nodes = getMenuItems(id);
    additionalNodes.forEach((node: MultilevelNodes) => {
        const exists = nodes.find((n: MultilevelNodes) => n.doctemplate === node.doctemplate);
        if (!exists) nodes.push(node);
    });
    return nodes;
}

function recursiveMenuBuild (item: MultilevelNodes, indexes: number[] = []) {
    if (!item.items) return menuItem(item, indexes);
    else return menuLevel(item, indexes);
}

function menuItem (node: MultilevelNodes, indexes: number[]) {
    const onClick = ({ item, key, keyPath }: {item: any, key: any, keyPath: any}) => {
        if (node.onSelected) node.onSelected();
    }
    const onSelect = ({ item, key, keyPath, selectedKeys }: {item: any, key: any, keyPath: any, selectedKeys?: any}) => {
    }
    const onExport = (event: Event) => {
        event.preventDefault();
        event.stopPropagation();
        if (node.onExport) node.onExport();
    }
    const onRemove = (event: Event) => {
        event.preventDefault();
        event.stopPropagation();
        if (node.onRemove) node.onRemove();
    }
    const label = itemLabel(node.doctemplate);
    const icon = <Icon type={node.imageIcon || "function"} style={{ fontSize: '16px' }} theme="outlined" />
    return (
        <Menu.Item
            key={indexes.join('_')}
            onClick={onClick}
            onSelect={onSelect}
            icon={icon}
        >
            {label}
            {node.onExport
                ? <Icon type="export" style={{ lineHeight: 'inherit', fontSize: '16px', marginRight: 0, float: 'right' }} theme="outlined" onClick={onExport}/>
                : <></>
            }
            {node.onRemove
                ? <Icon type="minus" style={{ lineHeight: 'inherit', fontSize: '16px', marginRight: 20, float: 'right' }} theme="outlined" onClick={onRemove}/>
                : <></>
            }
        </Menu.Item>
    )
}

function menuLevel (node: MultilevelNodes, indexes: number[]) {
    if (!node.items) return menuItem(node, indexes);
    const items = node.items.map((subitem: MultilevelNodes, i: number) => {
        const newindexes = indexes.concat([i]);
        return recursiveMenuBuild(subitem, newindexes);
    });
    const onAdd = (event: Event) => {
        event.preventDefault();
        event.stopPropagation();
        if (node.onAdd) node.onAdd();
    }
    const onRemove = (event: Event) => {
        event.preventDefault();
        event.stopPropagation();
        if (node.onRemove) node.onRemove();
    }
    const label = itemLabel(node.doctemplate);
    const icon = <Icon type={node.imageIcon || "more"} style={{ fontSize: '16px' }} theme="outlined" />
    return (
        <SubMenu
            key={indexes.join('_')}
            title={
                <>
                    <span>{label}</span>
                    {node.onRemove
                        ? <Icon type="minus" style={{ lineHeight: 'inherit', fontSize: '16px', marginRight: 18, float: 'right' }} theme="outlined" onClick={onRemove}/>
                        : <></>
                    }
                    {node.onAdd
                        ? <Icon type="plus" style={{ lineHeight: 'inherit', fontSize: '16px', marginRight: 20, float: 'right' }} theme="outlined" onClick={onAdd}/>
                        : <></>
                    }
                </>
            }
            icon={icon}>
            {items}
        </SubMenu>
    )
}

