import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import ark from 'remark-ark';
import rmath from 'remark-math';
import Tex from '@matejmazur/react-katex';
import 'katex/dist/katex.min.css';
import componentMap from './ComponentMap';
import {PlayCircleOutlined} from '@ant-design/icons';

export interface MarkdownProps {
    mode: any;
    children: any;
    handleSubmit?: Function;
    execute?: any;
}

export default class Markdown extends React.Component<MarkdownProps,any> {
    renderers: any;
    // components that are on course of being rendered
    components: any;
    nodes: any;

    constructor (props: MarkdownProps) {
        super(props);
        this.state = {
            simplifiedText: props.children,
            components: {},
            values: {},
        }
        this.renderers = {};
        this.components = {};
        this.nodes = {};
    }

    componentDidMount () {
        return this.setData();
    }

    componentDidUpdate (prevProps: any, prevState: any) {
        // only if props change!!
        // TODO this.setData();
    }

    async setData () {
        this.components = {};
        this.renderers = {
            paragraph: (props: any) => <div {...props} />,
            inlineMath: ({value}: any = {}) => <Tex math={value} />,
            math: ({value}: any = {}) => <Tex block math={value} />,
            arkmd: (data: any) => {
                // children - React components
                const {node} = data;
                const label = node.children[0].value;
                if (this.state.components[label]) {
                    return this.state.components[label];
                }
                return <span></span>;
            },
        }

        const [nodes, simplifiedText] = extractNodes(this.props.children);
        this.nodes = nodes;
        const finalText = forceNewLine(simplifiedText);

        this.setState({components: {}, simplifiedText: finalText});

        if (this.props.execute) {
            for (let label of Object.keys(nodes)) {
                await this.setComponent(label);
            }
        }

    }

    async setValueInEnv (label: string, value: any) {
        const {execute: ee} = this.props;
        let labelValue = ee.transform(null);
        if (value) {
            labelValue = `(mquote ${ee.transform(value)})`;
        }

        await ee.execute({
            data: {body: `(def! ${label} ${labelValue} )`},
            sender: {},
            conversationId: 'aa',
            id: 10,
        });
    }

    async setValue (label: string, value: any) {
        const {values} = this.state;
        values[label] = value;
        await this.setValueInEnv(label, value);
        this.setState({values});
    }

    async setComponent (label: string) {
        const {execute: ee} = this.props;
        const {values} = this.state;
        const {expression} = this.nodes[label];

        // this component is on course of being rendered
        this.components[label] = true;

        // initialize tags
        // TODO a new ee
        await this.setValueInEnv(label, values[label]);
        const result = await ee.execute({
            data: {body: expression},
            sender: {},
            conversationId: 'aa',
            id: 10,
        });

        let component;
        const _values: any = {};
        if (result && result.result && result.result.items) {
            // TODO - do we ever have more than 1 item??
            const items = result.result.items.map((item: any, i: number) => {

                const handleSubmit = async (value: any) => {
                    await this.setValue(label, value);

                    // rerender everything after a value changed
                    this.setData();
                }

                const _value = this.state.values[label];
                if (!_value && item.data.value) {
                    _values[label] = item.data.value;
                }
                if (item.onlyView) {
                    _values[label] = item.data.value;
                }

                const Component = componentMap[item.type];
                const key = label + i;
                if (!Component) return <span key={key}></span>;
                return <Component key={key} mode={this.props.mode} execute={this.props.execute} id={key} data={item.data} value={_value} onSelected={handleSubmit} />
            });

            component = (
                <>
                    {items}
                </>
            );
        }
        else if (result && result.errors && result.errors.length > 0) {
            component = (<p>{result.errors.map((e: any) => typeof e === 'object' ? e.message : e).join('\n')}</p>)
        }
        const {components} = this.state;
        components[label] = component;

        // component is null/nil - happens when there is a conditional rendering
        // in taylor
        if (!component) {
            this.components[label] = false;
        }

        if (Object.keys(_values).length > 0) {
            for (let key of Object.keys(_values)) {
                await this.setValue(key, _values[key]);
            }
        }
        this.setState({components});
    }

    render () {
        const {renderers} = this;
        const {handleSubmit} = this.props;
        const {simplifiedText} = this.state;

        const onCommit = () => {
            if (handleSubmit) handleSubmit(this.state.values);
        }

        const btn = handleSubmit ? <PlayCircleOutlined className="ActionButton" onClick={onCommit} /> : <></>;

        return (
            <>
            <ReactMarkdown renderers={renderers} plugins={[
                gfm, ark, rmath,
            ]} children={simplifiedText} />
            {btn}
            </>
        )
    }
}

function extractLabelExpression (value: string) {
    const colonndx = value.indexOf(':');
    const label = value.slice(0, colonndx).trim();
    const expression = value.slice(colonndx + 1).trim();
    return [label, expression];
}

function extractNodes (value: string) {
    if (!value) return [{}, ''];
    let nodes: any = {};
    let newvalue = '';
    let openIndex = 0;
    let closeIndex = 0;
    let opened = 0;
    let closed = 0;
    for (let i = 0; i < value.length; i++) {
        const char = value[i];
        if (char === '<') {
            opened ++;
            if (opened === 1) openIndex = i + 1;
        }
        else if (char === '>') {
            closed ++;
        }
        if (opened > 0 && opened === closed) {
            const _value = value.slice(openIndex, i);
            const [label, expression] = extractLabelExpression(_value);

            const node = {
                value: _value,
                label,
                expression,
                start: openIndex,
                end: i - 1,
            };
            nodes[label] = node;

            newvalue += value.slice(closeIndex, openIndex - 1);
            newvalue += `|${label}|`;
            opened = 0;
            closed = 0;
            closeIndex = i + 1;
        }
    }
    newvalue += value.slice(closeIndex);
    if (!newvalue) newvalue = value;
    return [nodes, newvalue];
}

function forceNewLine (text: string) {
    const parts = text.split('\n');
    // "a\nb\n\nc\nd".split('\n') -> ["a", "b", "", "c", "d"]
    const newparts = parts.map((part: string, i: number) => {
        if (part === '' || parts[i + 1] === '' || !parts[i + 1]) return part;
        return part + '\\';
    });
    return newparts.join('\n');
}
