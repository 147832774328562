const mottos = [
    'social. refined.',
    'social. reloaded.',
    'social. remastered.',
    'social. reimagined.',
    'community with nothing but your best selves', 'Be all that you can be. For everyone.',
    'programming turned social',
    'civilization. now digital.',
    'embarking civilization',
    'actualized humanity',
    'conversation. essentialized.',
    'conversation. decentralized.',
    'life. together now.',
    'communicate more',
    'interaction. computer-aided.',
    // 'information. proven.',
    'the world. synchronized.',
    // 'trust: computed.',
    // 'what can I prove?',
    'waddaya say?',
    'the blessing in disguise',
    // 'in proof we trust',
    // 'the relationship of relationships',
    'keep your data yours',
    'accept nothing less',
    'make destiny your own',
    'accept no harm',
    'analyse & improvise',
    // 'control language precision',
    // 'assert the truth',
    // 'be an institution',
    // 'social: programmable.',
    'uncensorable speech',
]

export default mottos;
