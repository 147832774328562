import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Modal as ReactModal } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import gfm from 'remark-gfm';

const _text = `
# social ark

This is alpha-quality. Expect a cornucopia of bugs and unpleasentries.

## what is it? looks like a chat..

It is a chat too, but with some twists. Many will be demoed, some of them will open new programming vistas.

The chat-like app uses taylor programs and markdown for it's interactions. Everyone is able to add functionality to the menu and then export it to their conversation peers.

## how does it work?

It uses a set of transport layers: libp2p, sockets, webRTC, (and others will be added). It aims to become decentralized, end-to-end encrypted, drop-in communicator.

## how do I start a conversation

1. You add a contact by copying their invitation in your great input area and press the send icon.
2. Their icon will appear in the left side and you are in the same conversation.
3. To invite somebody: menu/account/add contact present the qr code or copy it with a click and send it by other means.

## why?

* this software will eventually demo new ways of human digital interaction
* decentralization of communication
* more reasons will become apparent in time..


`

export default function InfoComponent (props: any) {
    const {isVisible, onClose} = props;

    const [text, setText] = React.useState(_text);

    React.useEffect(() => {
        async function setup () {
            const resp = await fetch("https://gist.githubusercontent.com/loredanacirstea/86db1cb9a6c7f67c2f2b8cd81137a157/raw");
            const text = await resp.text();
            setText(text);
        }
        setup()
    }, []);


    return (
        <ReactModal
            className="ChatModal"
            wrapClassName="ChatModalContainer"
            visible={isVisible}
            closable={false}
            mask={false}
            destroyOnClose={true}
            style={{
                top: 20,
            }}

            bodyStyle={{

            }}

            footer={[
                <CloseCircleOutlined style={{float: 'left'}} key={0} onClick={onClose}/>,
                <CloseCircleOutlined style={{float: 'right'}} key={1} onClick={onClose}/>,
            ]}
        >
            <div className="InfoComponent">
                <ReactMarkdown plugins={[gfm]} children={text} />
            </div>
        </ReactModal>
    )
}
