import React from 'react';
import moment from 'moment';
import { DatePicker, Space } from 'antd';

interface DateTimeProps {
    onSelected: (...args: any) => any,
}

export default function DateTime (props: DateTimeProps) {
    const {onSelected} = props;

    // @ts-ignore
    const now: any = new moment();
    const [startDate, setStartDate] = React.useState(now);

    onSelected(startDate.unix());

    function onChange(value: any, dateString: string) {
        console.log('Selected Time: ', value);
        console.log('Formatted Selected Time: ', dateString);

        setStartDate(value);
        onSelected(value.unix());
    }

    function onOk(value: any) {
        console.log('onOk: ', value);
        onChange(value, '');
    }

    return (
        <Space direction="vertical" size={12}>
            <DatePicker showTime onChange={onChange} onOk={onOk} />
        </Space>
    );
}
