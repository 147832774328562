import React from 'react';
import { Select } from 'antd';
import componentMap from '../markdown/ComponentMap';
const {Option} = Select;

const UiSelect = (props: any) => {
    const {data, value, styles, onSelected} = props;
    const {options} = data;
    let _value = value || data.value;
    if (_value) {
        _value = typeof _value === 'string' ? _value : _value.label;
    }
    const [selectedOption, setSelected] = React.useState(_value);

    let maxlen = 5;
    let _options: any = [];

    if (options instanceof Object) {
        _options = Object.keys(options).map((label: string) => {
            maxlen = Math.max(maxlen, label.length);
            if (typeof options[label] === 'object' && options[label].type) {
                return {label, ...options[label]}; // value, type, data
            }
            return {label, value: options[label]};
        });
    }
    if (options instanceof Array) {
        _options = options.map((item: any, i: number) => {
            maxlen = Math.max(maxlen, item.label.length);
            return {label: item.label, value: i};
        });
    }

    const width = Math.min(maxlen * 8 + 55, 600);

    const onChange = (option: any) => {
        let _option = option;
        if (options instanceof Array) _option = options[option];
        setSelected(option);
        onSelected(_option);
    }

    const optionComponents = _options.map((option: any, i: number) => {
        if (!option.type)
            return (
                <Option key={i} value={option.value}>{option.label}</Option>
            )
        const Component = componentMap[option.type];
        const key = option.label + i;
        return <Option key={i} value={option.value}><Component id={key} data={option.data} /></Option>
    });

    return (
        <Select style={{ width, ...styles }} value={selectedOption} onChange={onChange}>
            {optionComponents}
        </Select>
    );
}

export default UiSelect;
