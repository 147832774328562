const mainBgColorDark = '#000';
const mainBorderColorDark = 'rgb(80, 80, 80)'; // '#4c4c4c';
const mainColorDark = '#aaa';

const mainBgColorLight = 'rgb(230, 230, 230)';
const mainBorderColorLight = 'rgb(80, 80, 80)'; // '#7c7c7c';
const mainColorLight = 'rgb(46, 46, 46)';

const styles: any = {
    borderRadius: 10,
    dark: {
        common: {
            backgroundColor: mainBgColorDark,
            borderColor: mainBorderColorDark,
            color: mainColorDark,
        },
        message: {
            user1: {
                backgroundColor: 'rgb(28, 28, 37)',
            }
        },
        chatinput: {
            backgroundColor: 'rgb(20, 20, 20)',
        },
        button: {
            background: '#333',
        },
    },
    light: {
        common: {
            backgroundColor: mainBgColorLight,
            borderColor: mainBorderColorLight,
            color: mainColorLight,
        },
        message: {
            user1: {
                backgroundColor: 'rgb(186 186 200)',
            }
        },
        chatinput: {
            backgroundColor: '#fff',
        },
        button: {
            background: '#b4b4b4',
        }
    },
}

export const WIDTH_LIMIT = 800;

export default styles;
