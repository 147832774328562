const styles = {
    chatbubbleWrapper: {
      overflow: 'hidden',
    },
    chatbubble: {
      backgroundColor: '#0084FF',
      borderRadius: 20,
      marginTop: 1,
      marginRight: 'auto',
      marginBottom: 1,
      marginLeft: 'auto',
      maxWidth: 425,
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 14,
      paddingRight: 14,
      width: '-webkit-fit-content',
    },
    chatbubbleOrientationNormal: {
      float: 'right',
    },
    recipientChatbubble: {
      backgroundColor: '#ccc',
    },
    recipientChatbubbleOrientationNormal: {
      float: 'left',
    },
    p: {
      color: '#FFFFFF',
      fontSize: 16,
      fontWeight: '300',
      margin: 0,
    },
};

export default styles;
