/* eslint-disable */
import React, {useState} from 'react';
import Cron, {CronError} from 'react-js-cron';


export default function CronBuild(props: any) {
    // console.log('CronBuild', props);
    const {onSelected, data} = props;
    const {mode} = data;

    const defaultValue = '30 5 * * 1,6';
    const [value, setValue] = useState(defaultValue);
    const [error, onError] = useState<CronError>();

    const customSetValue = (newValue: string) => {
        setValue(newValue);
        // onSelected(newValue);
    }

    return (
        <Cron value={value} setValue={customSetValue} onError={onError} />
    )
  }
