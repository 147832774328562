import {chat as chatUtils} from '@ark-us/chat-manager';
const {Message: ChatMessage} = chatUtils;

export interface TypingMessageProps {
    message: typeof ChatMessage;
}

export function TypingMessage (props: TypingMessageProps) {
    return (
        <div>
            <img className="btn-rec" src="./icons/typing-rec.gif" alt="typing"/>
        </div>
    );
}

export function NoTypingMessage (props: TypingMessageProps) {
    return (
        <div>
            <img className="btn-rec" src="./icons/blank.png" alt="notyping"/>
        </div>
    );
}
