import React from 'react';
import { Modal as ReactModal } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import ArkMarkdown from '../markdown/Markdown';
import './Modal.css';

export interface ModalProps {
    mode: any;
    width?: number;
    height?: number;
    requireAnswer?: boolean;
    data: any;
    message: any;
    onClose?: any;
    isOpen?: boolean;
    chatManager: any;
    eeExecution: any;
}

const replaceLabelsWithValues = (command: string, valuesPerLabel: any, transform?: Function) => {
    const labels = Object.keys(valuesPerLabel);
    for (let label of labels) {
        const rgx = new RegExp(label, 'g');
        let value = valuesPerLabel[label];
        if (transform) value = transform(value);
        command = command.replace(rgx, value);
    }
    return command;
}

const Modal = (props: ModalProps) => {
    const {
        chatManager, mode, data, onClose, isOpen=false,
        eeExecution,
    } = props;
    const openModal = isOpen;

    const _onSendMessage = async (valuesPerLabel: any) => {
        let command = replaceLabelsWithValues(data.command, valuesPerLabel, eeExecution.transform);

        // we expect a taylor command that needs to be executed
        // it can be a taylor command that sends the message to somebody
        command = chatManager.executeNowMarker + command;
        chatManager.handleCommand(data.conversationId, command)
        onClose();
    }

    const content = (
        <ArkMarkdown mode={mode} handleSubmit={data.command ? _onSendMessage : undefined} execute={eeExecution}>
            {data.label}
        </ArkMarkdown>
    )

    const modal = (
        <ReactModal
            className="ChatModal"
            wrapClassName="ChatModalContainer"
            visible={openModal}
            closable={false}
            mask={false}
            destroyOnClose={true}
            style={{
                top: 20,
            }}

            bodyStyle={{

            }}

            footer={[
                <CloseCircleOutlined style={{float: 'left'}} key={0} onClick={onClose}/>,
                <CloseCircleOutlined style={{float: 'right'}} key={1} onClick={onClose}/>,
            ]}
        >
            <div className="ChatModalContent">
                {content}
            </div>
        </ReactModal>
    )
    return modal;
}

export default Modal;
