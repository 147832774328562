import React from 'react';
import ChatFeed from '../components/ChatFeed';
import ChatInput from './ChatInput/ChatInput';
import {ButtonIcon} from './Buttons.js';
import ChatMenu from './Menu/ChatMenu';
import MessageMenu from './Menu/MessageMenu';
import CustomChatBubble from './ChatMessage/ChatMessage';
import styles from '../styles';
import './Chat.css';
import {chat as chatUtils, contacts as contactUtils} from '@ark-us/chat-manager';

const {Conversation} = contactUtils;
const {MessageStatus, Message: ChatMessage} = chatUtils;

export interface ChatProps {
    mode: 'light' | 'dark',
    conversation: typeof Conversation,
    chatManager: any,
    inputValue: any,
    menuItems?: any,
}

export default class Chat extends React.Component<ChatProps, any> {
    onMessage: any;
    inputRef: any;
    conversationInstance: any;
    eeExecution: any;

    constructor(props: ChatProps) {
        super(props);

        this.state = {
            messages: [],
            is_typing: false,
            showMenu: false,
            disabled: false,
        };

        // @ts-ignore
        this.onMessage = this._onMessage.bind(this);
        this.inputRef = React.createRef<HTMLInputElement>();
    }

    async componentDidMount () {
        this.setConversationInstance();
        await this.setData();
        // @ts-ignore
        this.props.chatManager.apptarget.addEventListener('chatmessage', this.onMessage);
    }

    setConversationInstance () {
        const {conversation, chatManager} = this.props;
        this.conversationInstance = chatManager._conversations[conversation.value.id.value];
        this.eeExecution = {
            execute: (message: any) => {
                return this.conversationInstance.ee.eval(message, this.props.chatManager.uiExecutionHandlers);
            },
            transform: (jsObj: any) => this.conversationInstance.toEE(jsObj),
        }
    }

    async componentDidUpdate (oldProps: ChatProps) {
        const {conversation, inputValue} = this.props;
        this.setConversationInstance();
        if (oldProps.inputValue !== inputValue) {
            this.setInputValue(inputValue);
        }
        let notchanged = oldProps.conversation.value.id.value === conversation.value.id.value;
        if (notchanged) return;
        this.setData();
    }

    componentWillUnmount () {
        this.props.chatManager.apptarget.removeEventListener('chatmessage', this.onMessage);
    }

    disablesChat (message: typeof ChatMessage) {
        return false;
        // if (message.type === 'execute' && message.sender.id !== this.props.chatManager.identity.public.id) {
        // if (message.type === 'execute') {
        //     return true;
        // }
        // if (message.data?.view?.result?.requireAnswer) {
        //     return true;
        // }
        // if (message.type === 'message' && message.data.body[0] === '(') {
        //     return true;
        // }
        // return false;
    }

    async setData () {
        const {conversation, chatManager} = this.props;
        const conversationId = conversation.value.id.value;
        console.log('----setActive', conversationId);

        let stored = [];
        // console.log('chatManager._conversations', chatManager._conversations, conversationId, chatManager);

        if (!chatManager._conversations[conversationId]) {
            console.warn('No conversation instance found');
            return;
        }

        const allconvos = await chatManager._conversations[conversationId].getMessages();
        for (let i of allconvos) {
            i.user = i.sender.id === chatManager.identity.value.public.value.id.value ? 0 : 1;
            stored.push(i);
        }

        const stateUpdate: any = {messages: stored};

        if (stored.length > 0) {
            stateUpdate.disabled = this.disablesChat(stored[stored.length - 1]);
        }

        this.setState(stateUpdate);

        setTimeout(() => {
            const messageContainer = document.getElementsByClassName('chat-history')[0];
            messageContainer.scrollTo({
                top: messageContainer.scrollHeight,
                left: 0,
                behavior: 'smooth'
            })
        }, 500);
    }

    async setInputValue(value: string, dataObj?: any) {
        this.setState({showMenu: false});
        this.props.chatManager.handleCommand(this.props.conversation, value, dataObj);
    }

    _onMessage (event: any) {
        const message = event.detail;
        const {data, type} = message;
        if (message.conversationId !== this.props.conversation.value.id.value) return;
        // console.debug('Chat _onMessage', message);
        switch (type) {
            case 'typing':
                this.setState({ is_typing: data.started });
                break;
            case 'received':
                break;
            //     return this.onMessageReceived(data);
            // case 'sent':
            //     return this.onSentMessage(data);
            case 'message':
                return this.onMessageReceived(message);
            case 'delivery':
                return this.onMessageDelivered(data);
            case 'read':
                return this.onMessageSeen(data);
            case 'execute':
                return this.onMessageReceived(message);
            case 'system':
                return this.onMessageReceived(message);
            default:
                return;
        }
    }

   async onMessageReceived (message: any) {
        const {chatManager} = this.props;
        const {messages} = this.state;

        const newmessages = messages.concat(
            {
                user: message.sender.id === chatManager.identity.value.public.value.id.value ? 0 : 1,
                ...message,
            }
        );
        const stateUpdate: any = {is_typing: false, messages: newmessages};
        stateUpdate.disabled = this.disablesChat(message);
        this.setState(stateUpdate);
    }

    async onSentMessage (message: any) {
        // console.log('---onSentMessage', message);
        const {id} = message;
        const {messages} = this.state;
        const indx = messages.findIndex((item: any) => item.id === id);
        // TODO: proper status
        if (indx >= 0 && message.type === 'message') {
            messages[indx].status = MessageStatus.sent;
        }
        else if (message.type !== 'message') {
            messages.push(message);
        }
        const disabled = this.disablesChat(message);
        this.setState({messages, disabled});
    }

    onMessageDelivered (message: any) {
        const {id} = message.data;
        const {messages} = this.state;
        const indx = messages.findIndex((item: any) => item.id === id);
        if (indx < 0) return;
        messages[indx].status = Math.max(messages[indx].status, MessageStatus.received)
        this.setState({messages});
    }

    onMessageSeen (message: any) {
        const {id} = message.data;
        const {messages} = this.state;
        const indx = messages.findIndex((item: any) => item.id === id);
        if (indx < 0) return;
        messages[indx].status = MessageStatus.seen;
        this.setState({messages});
    }

    async onSendMessage (value: string) {
        if (!value) return;
        const {conversation} = this.props;

        // @ts-ignore
        const {messages} = this.state;

        if (isViewCommand(value)) {
            const conversationId = conversation.value.id.value;
            const command = getCommandFromView(value);
            const doctemplate = `# Environment viewer

<viewCommand:(ui-input {"placeholder" "command" "value" (mquote ${command}) })>
<(ui-json (eval (read-string viewCommand)) )>`;
            const doctemplateStr = this.eeExecution.transform(doctemplate);
            value = `!(modal "${conversationId}" (quote ${doctemplateStr}) "")`;
        }

        const message = await this.props.chatManager.handleCommand(conversation, value);
        if (!message) return;

        let chatMessage = {
            ...message,
            user: 0,
        }
        // console.log('chatMessage', chatMessage)
        // chatMessage.data.status = MessageStatus.sending;
        chatMessage = (await this.props.chatManager._conversations[conversation.value.id.value].prepareMessages([chatMessage]))[0];

        const newmessages = messages.concat(chatMessage);
        this.setState({messages: newmessages});
    }

    onTyping (status: boolean) {
        const {conversation} = this.props;
        const data = {
            data: {started: status, ended: status === false },
            type: 'typing',
        }
        this.props.chatManager.sendMessage(conversation, data);
    }

    showMessageMenu (message: any) {
        const {showMenu} = this.state;
        if (showMenu) this.hideMessageMenu();
        else this.setState({activeMessage: message, showMenu: true});
    }

    hideMessageMenu () {
        this.setState({activeMessage: null, showMenu: false});
    }

    render () {
        const {conversation, mode} = this.props;
        const {messages, is_typing, showMenu, disabled, activeMessage} = this.state;
        // ids must be different for typing - messages are memoed
        let _messages = messages.concat(
            is_typing ? {id: -3, user: 1, message: {body: ''}, type: 'typing'} : {id: -2, user: 1, message: {body: ''}, type: 'notyping'},
        )

        _messages = _messages.map((m: any) => {
            m.mode = mode;
            if (m.type !== 'message' && m.type !== 'execute') return m;
            m.eeExecution = this.eeExecution;
            m.onMenu = async () => {
                this.showMessageMenu(m);
            }
            m.onClick = async (command: string, ...args: any) => {
                // console.log('----onClick', command, args);
                const conv = this.props.chatManager._conversations[conversation.value.id.value];
                if (!conv[command]) {
                    console.error('Command unknown', command);
                    return;
                }
                const result = await conv[command](...args, this.props.chatManager.uiExecutionHandlers);
                // console.log('------------result', result);
                const index = messages.findIndex((item: any) => item.id === m.id);
                // console.log('--*******-index', index);
                if (index >= 0) {
                    messages[index].data.result = result;
                    this.setState(messages);
                }

                if (command === 'execute') this.setState({disabled: false});
            }
            return m;
        });

        const selectedListItem = (event: any) => {
            // console.log('selectedListItem', event);
        }
        const selectedLabel = (value: string) => {
            // console.log('selectedLabel', value);
        }

        const selectCallback = (data: any, marker: string) => (command: any, doctemplate: any) => {
            this.hideMessageMenu();
            const dataObject = {[marker]: data}
            let expr = command;

            if (!this.props.chatManager.isExecutableNow(command)) {
                const doctemplateStr = this.eeExecution.transform(doctemplate);
                const commandStr = this.eeExecution.transform(command);
                const conversationId = conversation.value.id.value;
                expr = `!(modal "${conversationId}" (quote ${doctemplateStr}) (quote ${commandStr}))`;
            }
            this.setInputValue(expr, dataObject);
        }
        let menu;
        if (showMenu && !activeMessage) {
            const onSelectCallback = selectCallback(conversation, 'conversation').bind(this);
            menu = (
                <ChatMenu
                    mode={this.props.mode}
                    id="conversation"
                    conversationId={conversation.value.id.value}
                    menuItems={this.props.menuItems}
                    selectCallback={onSelectCallback}
                    selectedLabel={selectedLabel}
                    selectedListItem={selectedListItem}
                    chatManager={this.props.chatManager}
                />
            )
        }
        if (showMenu && activeMessage) {
            const onSelectCallback = selectCallback(activeMessage, 'message').bind(this);
            menu = (
                <MessageMenu
                    mode={this.props.mode}
                    conversationId={conversation.value.id.value}
                    message={activeMessage}
                    selectCallback={onSelectCallback}
                    chatManager={this.props.chatManager}
                />
            )
        }

        const _styles = {
            borderColor: styles[mode].common.borderColor,
            color: styles[mode].common.color,
        }
        const inputStyles = {
            ...styles[mode].common,
            ...styles[mode].chatinput,
        }

        let inputComponent = <ChatInput ref={this.inputRef} disabled={disabled} mode={mode} styles={_styles} inputStyles={inputStyles} inputPlaceholder={""} handleSubmit={this.onSendMessage.bind(this)} onTyping={this.onTyping.bind(this)}/>

        return (
            <div style={{height: '100%'}}>
                <ChatFeed
                    mode={mode}
                    chatBubble={CustomChatBubble}
                    messages={_messages} // Array: list of message objects
                    hasInputField={false} // Boolean: use our input, or use your own
                    showSenderName // show the name of the user who sent the message
                    bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
                />
                {showMenu ? menu : <span></span>}
                <div className="ChatInputContainer">
                    <ButtonIcon size={40} type="menu" onClick={() => {
                        this.setState({showMenu: !showMenu, activeMessage: null});
                    }} />
                    {inputComponent}
                </div>
            </div>
        )
    }
}

function isViewCommand (command: string) {
    if (!command) return false;
    let cmd = command.trim();
    if (cmd[0] !== '?') return false;
    if (cmd.slice(1).trim()[0] !== '(') return false;
    return true;
}

function getCommandFromView (command: string): string {
    let ndx = command.indexOf('?');
    return command.slice(ndx + 1).trim();
}
