import React from 'react';

const iconmap = {
    'send': 'send',
    'question': 'question',
    'sending': 'sending',
    'link': 'link',
    'check-filled': 'check2',
    'check': 'check1',
    'arrow-down': 'down',
    'plus': 'plus',
    'menu': 'menu',
}

export function ButtonImage (props) {
    const {type, onClick} = props;
    const src = `./icons/${iconmap[type] || type}.png`;
    return(
        <button className="btn">
            <img className="btn-image" src={src} alt="send" onClick={onClick} />
        </button>
    )
}

export function ButtonIcon (props) {
    const {size = 15, type, onClick, className = ''} = props;
    const src = `./icons/${iconmap[type] || type}.png`;
    const height = `${size}px`;
    return(
        <button className={className} style={{height, background: 'transparent', border: 'transparent', padding: 0}}>
            <img style={{ height }} src={src} alt="send" onClick={onClick} />
        </button>
    )
}
