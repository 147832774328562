const styles = {
    chatbubbleWrapper: {
      marginTop: 10,
      marginBottom: 10,
      overflow: 'auto',
      position: 'relative',
    },
    bubbleGroupHeader: {
      margin: 0,
      fontSize: 14,
      fontWeight: '400',
      color: '#999',
    },
};

export default styles;
