import React from 'react';
import ChatMenu from './ChatMenu';

export default function MessageMenu (props: any) {
    const {mode, conversationId, chatManager, selectCallback} = props;

    return (
        <ChatMenu
            mode={mode}
            id="message"
            conversationId={conversationId}
            selectCallback={selectCallback}
            selectedLabel={() =>{}}
            selectedListItem={() => {}}
            chatManager={chatManager}
        />
    )
}
