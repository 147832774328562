import React from 'react';
import {
    FunctionOutlined,
    CompassOutlined,
    GiftOutlined,
    EuroCircleOutlined,
    FormOutlined,
    QuestionCircleOutlined,
    InfoCircleOutlined,
    SettingOutlined,
    ShoppingCartOutlined,
    EnvironmentOutlined,
    MoreOutlined,
    UserOutlined,
    MinusCircleOutlined,
    PlusCircleOutlined,
    ImportOutlined,
    ExportOutlined,
    DeleteOutlined,
    QrcodeOutlined,
    ScanOutlined,
    SmileOutlined,
    FrownOutlined,
    HeartOutlined,
    MehOutlined,
    DislikeOutlined,
    LikeOutlined,
    FireOutlined,
    FlagOutlined,
    ThunderboltOutlined,
    StarOutlined,
    BulbOutlined,
    CheckOutlined,
    CheckCircleFilled,
    PlayCircleOutlined,
    ShareAltOutlined,
    MessageOutlined,
} from '@ant-design/icons';

export const componentMap: any = {
    function: FunctionOutlined,
    compass: CompassOutlined,
    gift: GiftOutlined,
    euro: EuroCircleOutlined,
    form: FormOutlined,
    question: QuestionCircleOutlined,
    info: InfoCircleOutlined,
    setting: SettingOutlined,
    shoppingCart: ShoppingCartOutlined,
    environment: EnvironmentOutlined,
    more: MoreOutlined,
    user: UserOutlined,
    minus: MinusCircleOutlined,
    plus: PlusCircleOutlined,
    import: ImportOutlined,
    export: ExportOutlined,
    delete: DeleteOutlined,
    scan: ScanOutlined,
    qrcode: QrcodeOutlined,
    smile: SmileOutlined,
    frown: FrownOutlined,
    heart: HeartOutlined,
    meh: MehOutlined,
    like: LikeOutlined,
    dislike: DislikeOutlined,
    fire: FireOutlined,
    flag: FlagOutlined,
    thunderbolt: ThunderboltOutlined,
    star: StarOutlined,
    bulb: BulbOutlined,
    check: CheckOutlined,
    proof: CheckCircleFilled,
    play: PlayCircleOutlined,
    share: ShareAltOutlined,
    message: MessageOutlined,
}

export default function Icon (props: any) {
    const Component = componentMap[props.type] || FunctionOutlined;
    return <Component {...props} />
}

const categoryMap: any = {
    reply: [
        'smile', 'heart', 'frown', 'meh',
        'like', 'dislike', 'fire', 'flag',
        'gift', 'info',
        'thunderbolt', 'star', 'bulb',
    ]
}

export function getIconNames (category?: string) {
    if (category && categoryMap[category]) return categoryMap[category];
    return Object.keys(componentMap);
}
