import { MultilevelNodes } from 'react-multilevel-menu';

const BASE_NAME = 'ARKMENU_';

const MenuHandler = {
    get: (id: string): MultilevelNodes[] => {
        const menu: string | null = localStorage.getItem(BASE_NAME + id);
        if (!menu) return [];
        let nodes: MultilevelNodes[] = [];
        try {
            nodes = JSON.parse(menu);
            if (!nodes) return [];
        } catch (e) {
            return [];
        }
        return nodes;
    },
    set: (id: string, menu: MultilevelNodes[]) => {
        localStorage.setItem(BASE_NAME + id, JSON.stringify(menu));
    },
    remove: (id: string) => {
        localStorage.removeItem(BASE_NAME + id);
    },
    removeAll: () => {
        const keys = Object.keys(localStorage).filter((key: string) => {
            return key.includes(BASE_NAME);
        });
        for (let key of keys) {
            localStorage.removeItem(key);
        }
    }
}

export default MenuHandler;
