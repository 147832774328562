import React from 'react';
import {useWindowWidth} from '@react-hook/window-size/throttled';
import {contacts as contactUtils} from '@ark-us/chat-manager';
import Chat from '../components/Chat';
import Contacts from '../components/Contacts';
import Modal from '../components/Modal/Modal';
import LandingPage from './LandingPage';
import ChatMenu from '../components/Menu/ChatMenu';
import {ButtonIcon} from '../components/Buttons.js';
import {WIDTH_LIMIT} from '../styles';
import extendUi from './extendUi';
import './ChatView.css';

import ethPluginBuild from '../plugins/eth/index';


interface Plugin {
    menuItem: any;
    extensions: any;
}
const extendFromPlugin = (chatManager: any, plugin: Plugin) => {
    for (let method of plugin.extensions.items) {
        const name = plugin.extensions.label + '-' + method.label;
        chatManager.extendEE(name, method.value);
    }
}

const {Conversation} = contactUtils;

export interface ChatViewProps {
    mode: 'light' | 'dark',
    chatManager: any,
    conversations: typeof Conversation[],
}

const ChatView = (props: ChatViewProps) => {
    const {chatManager, mode} = props;

    const conversations = chatManager.conversations;

    const onlyWidth = useWindowWidth();
    const [conversation, setConversation] = React.useState<typeof Conversation | null>(null);
    const [items, setItems] = React.useState<typeof Conversation[]>(conversations);
    const [currentModal, setModal] = React.useState<any>();
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [showMenu, setShowMenu] = React.useState(false);
    const [audioNotif, setAudio] = React.useState<any>();
    const identity = chatManager.identity;
    const showWide = onlyWidth > WIDTH_LIMIT;

    chatManager.extendEE('modal', (conversationId: string, label: string, command: string) => {
        if (!conversationId) conversationId = conversation ? conversation.value.id.value : chatManager._conversations.self?.conversation.value.id.value;
        setModal({conversationId, label, command});
        setShowModal(true);
    });
    chatManager.extendEE('arklink', async (linkType: string) => {
        let url = 'ark://chat/';
        const urlContent = await chatManager.buildUrl(linkType);
        console.log('arklink', url + urlContent);
        return url + urlContent;
    });
    extendUi(chatManager);

    React.useEffect(() => {
        setItems(conversations);

        const allitems = items.length > 0 ? items : conversations;

        const onAddConversation = async (data: any) => {
            const _items = await chatManager.getConversations();
            setItems(_items);
            setModal(undefined);
            setShowModal(false);

            if (data.value.id.value !== chatManager.identity.value.public.value.id.value) {
                setConversation(data);
            }
        }

        const onRemoveConversation = async (id: string) => {
            const index = allitems.findIndex((item: any) => {
                return item.value.id.value === id;
            });
            allitems.splice(index, 1);
            const _items = [...allitems];
            setItems(_items);
            setModal(undefined);
            setShowModal(false);
        }

        const onAddDevice = async () => {
            // update conversations
            const conversations = await chatManager.getConversations();
            setItems(conversations);

            // go to self conversation
            const selfConv = chatManager._conversations.self.conversation;
            console.debug('APP selfConv', selfConv);
            setConversation(selfConv);
            setModal(undefined);
            setShowModal(false);
        }

        const onEnvironmentChange = async (event: any) => {
            const {detail} = event;
            const {data, type} = detail;
            console.debug('ChatView onEnvironmentChange', type, data);
            if (type === 'conversation') {
                if (data) {
                    onAddConversation(data);
                }
                else {
                    onRemoveConversation(detail.id);
                }
            }
            if (type === 'identity' || type === 'syncDataDevice') {
                const conversations = await chatManager.getConversations();
                setItems(conversations);
            }
            if (type === 'contact') {
                console.info('New contact added', data);
            }
            if (type === 'conversation_remove') {
                const index = allitems.findIndex((conv: any) => {
                    return conv.id === data;
                });
                allitems.splice(index, 1);
                setItems(allitems);
            }
            if (type === 'device_added') {
                onAddDevice();
            }
            if (type === 'execute') {
                // console.log('EXECUTE', data);
                const conversation = await chatManager.getConversation(data.conversationId);
                setConversation(conversation);
                setModal(undefined);
                setShowModal(false);
            }
            if (type === 'lastBlock') {
                const _conversations = await chatManager.getConversations();
                setItems(_conversations);
            }
        }
        chatManager.apptarget.addEventListener('chatdata', onEnvironmentChange);

        return function cleanup () {
            chatManager.apptarget.removeEventListener('chatdata', onEnvironmentChange);
        }

    // eslint-disable-next-line
    }, [conversations]);

    React.useEffect(() => {
        const onMessageForAudio = async (event: any) => {
            const message = event.detail;

            if (chatManager._conversations.self && message.conversationId === chatManager._conversations.self.conversation.value.id.value) return;

            if (message.conversationId && conversation && message.conversationId === conversation.value.id.value) return;

            if (message.type === 'message' || message.type === 'execute' || message.type === 'system') {
                if (!audioNotif) {
                    const _audio = new Audio('./notify.wav');
                    try {
                        _audio.load();
                        _audio.addEventListener('canplaythrough', () => {
                            console.log('loaded audio');
                            _audio.play().catch(console.warn);
                            setAudio(_audio);
                        });
                    } catch (e) {
                        console.warn(e);
                    }
                }
                if (audioNotif) audioNotif.play().catch(console.warn);
            }
        }
        chatManager.apptarget.addEventListener('chatmessage', onMessageForAudio);

        return function cleanup () {
            chatManager.apptarget.removeEventListener('chatmessage', onMessageForAudio);
        }
    // eslint-disable-next-line
    }, [conversation, audioNotif]);

    const onSelect = async (_item: typeof Conversation) => {
        setConversation(_item);
    }

    const eeExecution = {
        execute: (message: any) => {
            if (!props.chatManager._conversations.self) return;
            return props.chatManager._conversations.self.ee.eval(message, props.chatManager.uiExecutionHandlers);
        },
        transform: (jsObj: any) => {
            if (!props.chatManager._conversations.self) return;
            return props.chatManager._conversations.self.toEE(jsObj)
        },
    }
    const selectCallback = (command: any, doctemplate: string) => {
        let expr = command;
        if (!props.chatManager.isExecutableNow(command)) {
            const doctemplateStr = eeExecution.transform(doctemplate);
            const commandStr = eeExecution.transform(command);
            const conversationId = conversation?.value.id.value;
            expr = `!(modal "${conversationId}" (quote ${doctemplateStr}) (quote ${commandStr}))`;
        }
        setShowMenu(false);
        props.chatManager.handleCommand(props.chatManager._conversations.self.conversation, expr);
    }

    const ethPlugin = ethPluginBuild({transform: eeExecution.transform});
    extendFromPlugin(chatManager, ethPlugin);
    const conversationMenuItems = [ethPlugin.menuItem];

    let menu;
    if (showMenu) {
        menu = (
            <ChatMenu
                mode={mode}
                id="system"
                conversationId={props.chatManager._conversations.self.conversation.value.id.value}
                selectCallback={selectCallback.bind(this)}
                selectedLabel={() => {}}
                selectedListItem={() => {}}
                chatManager={props.chatManager}
            />
        )
    }

    const chat = conversation
        ? <Chat mode={mode} inputValue={null} chatManager={chatManager} conversation={conversation} menuItems={conversationMenuItems}/>
        : (
            <div className="LandingPageContainer">
                <LandingPage />
                {menu ? menu : <span></span>}
                <div className="SystemMenuBtn">
                    <ButtonIcon size={40} type="menu" onClick={() => {setShowMenu(!showMenu)}} />
                </div>
            </div>
        );

    let modalComponents: any = <span></span>;
    if (currentModal) {

        const onClose = () => {
            setModal(undefined);
            setShowModal(false);
        }
        const me = chatManager.identity.value.public.value.id.value;
        const data = {
            mode,
            sender: {id: me},
            identity: me,
        }
        const eeExecution = {
            execute: (message: any) => {
                if (!chatManager._conversations[currentModal.conversationId]) return;
                return chatManager._conversations[currentModal.conversationId].ee.eval(message, chatManager.uiExecutionHandlers);
            },
            transform: (jsObj: any) => {
                if (!chatManager._conversations[currentModal.conversationId]) return;
                return chatManager._conversations[currentModal.conversationId].toEE(jsObj)
            },
        }
        modalComponents = <Modal chatManager={chatManager} mode={mode} eeExecution={eeExecution} isOpen={showModal} data={currentModal} message={data} onClose={onClose}/>
    }

    const conversationName = conversation ? conversation.value.profile.value.name : '';

    const sidewidth = showWide ? 220 : 40;

    return (
        <div style={{display: 'flex', height: 'calc(100% - 75px)'}} >
            <div className="paddlr" style={{width: sidewidth}} >
                <div style={{
                    display: 'flex',
                    position: 'absolute',
                    top: '0px',
                    left: '40px',
                }}>
                </div>
                <Contacts mode={mode} showWide={showWide} identity={identity} items={items} selected={conversation} onSelect={onSelect}/>

            </div>
            <div className="paddlr" style={{width: `calc(100% - ${sidewidth}px)`, marginTop: '-25px'}} >
                <p className="ConversationName">{conversationName}</p>
                {chat}
            </div>
            {modalComponents}
        </div>
    );
};

export default ChatView;
