import React from 'react';
import UiSelect from '../ChatInput/ChatSelectInput';
import Timer from 'react-compound-timer';

// "multiple choice" "mchoice" "extended choice" "echoice" "extended mutliple choice" "emchoice" "essay" "essay" "value" "value" "dropdown" "dropdown"

// interface QuestionOption {
//     label: string;
//     value: any;
// }

// interface QuestionProps {
//     body: string;
//     type: 'yes/no' | 'choice';
//     options: QuestionOption[];
//     onCommand: any;
// }

function getTime (startTime: number, interval: number, units: string) {
    let value = interval;
    if (units === 'seconds') value *= 1000;
    else if (units === 'minutes') value *= 1000 * 60;
    else if (units === 'hours') value *= 1000 * 60 * 60;

    const diff = new Date().getTime() - startTime;
    let diffvalue = value - diff;
    if (diffvalue < 0) diffvalue = 0;
    return diffvalue;
}

function setTimer (ms: number): Promise<void> {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
}

export default function TimedQuestion (props: any) {
    const {command, onCommand, text, type, data, interval, unit} = props;
    const {mode, conversationId, isFromSelf} = data;

    const [computedCommand, setCommand] = React.useState();
    const [enabled, setEnabled] = React.useState(!isFromSelf);

    const timerTime = getTime(data.timestamp, parseInt(interval), unit);

    React.useEffect(() => {
        if (interval && unit) {
            setTimer(timerTime).then(() => {
                console.log('setTimer FINISHED')
                setEnabled(false);
            });
        }
    // eslint-disable-next-line
    }, [text, type, interval, unit]);

    const onSelected = (value: any) => {
        const _computedCommand = command(value);
        setCommand(_computedCommand);
    }

    const onClick = () => {
        if (typeof computedCommand !== 'undefined') {
            console.log('onCommand', computedCommand, conversationId);
            onCommand(computedCommand, conversationId);
            setEnabled(false);
            // TODO: store variable + timestamp when was stored
            // check
        }
    }

    let options = {};
    if (type === 'yes/no') {
        options = {yes: 'yes', no: 'no'};
    }

    return (
        <div>
            {enabled
                ? <Timer
                        initialTime={timerTime}
                        direction="backward"
                    >
                        {() => (
                            <React.Fragment>
                                <Timer.Minutes /> m
                                <Timer.Seconds /> s
                            </React.Fragment>
                        )}
                    </Timer>
                : <span></span>
            }
            <p>{text}</p>
            <UiSelect
                mode={mode}
                data={{options}}
                onSelected={onSelected}
            />

            {enabled
                ? <button onClick={onClick}>commit</button>
                : <span></span>
            }
        </div>
    )
}
