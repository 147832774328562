import commonStyles from '../../styles';

const {borderRadius} = commonStyles;

const chatbubble = {
    borderBottomRightRadius: borderRadius,
    borderBottomLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
    borderTopLeftRadius: borderRadius,
}

const SYSTEM_BUBBLE_STYLE_text = {
    fontSize: 16,
}

const SYSTEM_BUBBLE_STYLE_chatbubble = {
    ...chatbubble,
}

const CLASSIC_text = {fontSize: 16};
const CLASSIC_chatbubble = {
    ...chatbubble,
    paddingLeft: 7,
    paddingRight: 7,
    paddingTop: 7,
    paddingBottom: 5,
}

export const theme: any = {
    light: {
        BubbleBack1: 'rgb(186 186 200)',
        BubbleBack0: 'rgb(194 192 192)',
        SYSTEM_BUBBLE_STYLE: {
            text: {...SYSTEM_BUBBLE_STYLE_text},
            chatbubble: {
                ...SYSTEM_BUBBLE_STYLE_chatbubble,
                backgroundColor: 'rgb(193 202 195)',
            },
            userBubble: {},
        },
        CLASSIC: {
            text: {...CLASSIC_text},
            chatbubble: {...CLASSIC_chatbubble},
            userBubble: {},
        }
    },
    dark: {
        BubbleBack1: 'rgb(28 28 37)',
        BubbleBack0: 'rgb(54 54 54)',
        SYSTEM_BUBBLE_STYLE: {
            text: {...SYSTEM_BUBBLE_STYLE_text},
            chatbubble: {
                ...SYSTEM_BUBBLE_STYLE_chatbubble,
                backgroundColor: 'rgb(30 30 30)',
            },
            userBubble: {},
        },
        CLASSIC: {
            text: {...CLASSIC_text},
            chatbubble: {...CLASSIC_chatbubble},
            userBubble: {},
        }
    }
}
