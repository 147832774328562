import React from 'react';
import './DappMarkdown.css';
import Modal from '../Modal/Modal';
import QRCode from 'qrcode';
import TimedQuestion from '../Question/TimedQuestion';
import {clipboardCopy} from '../../utils';

export interface DappMarkdownProps {
    mode: any;
    children: any;
    data: any;
}

const sendMessage = (conversationId: string, message: any) => {
    // @ts-ignore
    const chatManager = window.ark;
    const conversation = chatManager._conversations[conversationId].conversation;
    return chatManager.sendMessage(conversation, message);
}

const onCommand = (command: string, conversationId: string) => {
    const message = {
        type: 'command',
        data: {
            body: command,
            conversationId: conversationId,
        },
    }
    sendMessage(conversationId, message);
}

export const uiButton = (obj: any) => {
    const onClick = () => {
        onCommand(obj.onClick, obj.conversationId);
    }

    return <button className="DappButton abtn" disabled={obj.fromSelf} onClick={onClick}>{obj.title}</button>
}

const uiText = (obj: any) => {
    return (<p>{obj.text}</p>);
}

var qropts = {
    color: {
      dark:"#000",
      light:"#aaa"
    }
}

const Qrcode = (props: any) => {
    const {url} = props;
    const ref = React.createRef<HTMLCanvasElement>();

    React.useEffect(() => {
        if (!ref.current) return;
        const canvas = ref.current;
        QRCode.toCanvas(canvas, url, qropts, function (error: Error) {
            if (error) console.error(error)
            // @ts-ignore
            canvas.style.height = '200px';
            // @ts-ignore
            canvas.style.width = '200px';
        });
    });

    const onClick = () => clipboardCopy(url);

    return (
        <canvas ref={ref} onClick={onClick} style={{height: '200px', width: '200px'}}></canvas>
    )
}

const componentMap: any = {
    'button': uiButton,
    'text': uiText,
    'modal': Modal,
    'qrcode': Qrcode,
    'timedquestion': TimedQuestion,
}

export default class DappMarkdown extends React.Component<DappMarkdownProps> {
    render () {
        // @ts-ignore
        let {children, data} = this.props;
        if (!(children instanceof Array)) children = [children];

        const components: any = [];

        for (let child of children) {
            if (!child) continue;
            const Component = componentMap[child.type]
            const component = (
                <Component
                    key={components.length}
                    {...child.data}
                    data={data}
                    conversationId={data.conversationId}
                    fromSelf={data.fromSelf}
                    onCommand={onCommand}
                />
            );
            components.push(component);
        }
        if (data.fromSelf) {
            const onClick = () => {
                onCommand('(rollback 1)', data.conversationId);
            }
            components.push(<button key={components.length} onClick={onClick}>rollback</button>);
        }

        return (
            <div>
                {components}
            </div>
        )
    }
}
