
import {contacts as contactUtils} from '@ark-us/chat-manager';

export const prepareContact = (identifier: string) => {
    console.log('prepareContact identifier', identifier)
    const partialContact: Partial<typeof contactUtils.Contact> = {};

    if (identifier[0] === '+') {
        partialContact.number = identifier;
    } else if (identifier.length === 64) {
        partialContact.publicKey = identifier;
    } else {// if (identifier.length === 46) {
        partialContact.peerId = identifier;
    }

    // TODO better id
    const c: typeof contactUtils.Contact = contactUtils.buildContact(partialContact);
    return c;
}

export const clipboardCopy = (text: string) => {
    const aux = document.createElement("input");
    aux.setAttribute("value", text);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
};

export const updateOrPush = (items: any, data: any) => {
    const index: number = items.findIndex((item: any) => {
        return item.value.id.value === data.value.id.value;
    });
    if (index >= 0) {
        items[index] = data;
    }
    else {
        items.push(data);
    }
    return items;
}

export const watchTheme = (callback: Function) => {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
        const newmode = e.matches ? "dark" : "light";
        console.log('watchTheme', newmode);
        callback(newmode);
    });
}

export function debounce(func: Function, wait: number, immediate: boolean = false) {
    let timeout: any;
    return function (...args: any) {
        // @ts-ignore
        const context = this;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export function itemLabel (label: string, max: number = 100): string {
    let newlabel = label.slice(0, max);
    if (newlabel.length < label.length) newlabel += '..';
    return newlabel;
}
