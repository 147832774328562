import React, { ChangeEvent } from 'react';
import './ChatInput.css';

const styles = {
  inputStyle: {
    border: 'none',
    borderTopWidth: '1',
    borderTopStyle: 'solid',
    borderTopColor: '#ddd',
    fontSize: '16',
    outline: 'none',
    padding: '30',
    width: '100%',
  } as React.CSSProperties,
};

export interface ChatInputProps {
  mode?: string;
  styles?: any;
  inputStyles?: any;
  inputPlaceholder: string;
  disabled: boolean;
  ref: any;
  handleSubmit: (msg: any) => void;
  onTyping?: (status: boolean) => void;
}

const TYPING_DELAY = 2000;

const ChatInput = React.forwardRef((props: ChatInputProps, inputRef: any) => {
  const { styles: _styles, inputStyles = {}, inputPlaceholder, disabled, handleSubmit, onTyping } = props;
  const [value, setValue] = React.useState('');
  const [isTyping, setTyping] = React.useState(0);

  const _onChange = (ev: ChangeEvent<HTMLInputElement>) => {
      const value = ev?.target?.innerText || '';
      setValue(value);
      if (onTyping) {
          if (!isTyping) onTyping(true);
          setTyping(new Date().getTime());
          setTimeout(() => {
            if (new Date().getTime() - isTyping >= TYPING_DELAY) {
              onTyping(false);
              setTyping(0);
            }
            else {
              onTyping(true);
            }
          }, TYPING_DELAY + 500);
      }
  }

  const __onSubmit = () => {
    handleSubmit(value);
    setValue('');
    if (inputRef && inputRef.current) {
      inputRef.current.innerText = '';
    }
  }

  const _onSubmit = (e: any) => {
    if (e && e.preventDefault) e.preventDefault();
    __onSubmit();
  }

  const onKeyPressed = (e: any) => {
    if (e.keyCode === 13 && !e.metaKey && !e.shiftKey) {
      e.preventDefault();
      __onSubmit();
    }
  }

  let classes = '';
  if (disabled) classes += ' ChatInputDisabled';

  return (
    <div className="ChatInputFormDiv" style={_styles || {}}>
        <div tabIndex={0} contentEditable={true} className={'input-large ' + classes} ref={inputRef} style={inputStyles || styles.inputStyle} placeholder={inputPlaceholder} onChange={_onChange} onInput={_onChange} onKeyDown={onKeyPressed}></div>
        {isTyping > 0
          ? <button className={"btn btn-raw " + classes} onClick={_onSubmit}>
              <img className="btn-send" src="./icons/typing-send.gif" alt="send" />
            </button>
          : <button className={"btn btn-raw " + classes}>
              <img className="btn-send" src="./icons/typing-send.png" alt="send" onClick={_onSubmit} />
            </button>
        }
    </div>
  );
});

export default ChatInput;
