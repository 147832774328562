import {getIconNames} from '../components/Icon';

export default function extendUi (chatManager: any) {
    const uiText = (content: string) => {
        const items = [
            {
                type: 'text',
                data: {value: content},
                onlyView: true,
            }
        ]
        return {items}
    }
    chatManager.extendEE('ui-text', uiText);

    const uiJson = (content: string) => {
        const items = [
            {
                type: 'json',
                data: {value: content},
                onlyView: true,
            }
        ]
        return {items}
    }
    chatManager.extendEE('ui-json', uiJson);

    const uiLink = (url: string, config?: any) => {
        config = config || {};
        const items = [
            {
                type: 'link',
                data: {
                    value: url,
                    label: config.label,
                    icon: config.icon,
                },
            }
        ]
        return {items}
    }
    chatManager.extendEE('ui-link', uiLink);

    const uiIcon = (content: string) => {
        const items = [
            {
                type: 'icon',
                data: {text: content},
            }
        ]
        return {items}
    }
    chatManager.extendEE('ui-icon', uiIcon);

    const uiInput = (data: any) => {
        const items = [
            {
                type: 'input',
                data, // {label, placeholder, value}
            }
        ]
        return {items}
    }
    chatManager.extendEE('ui-input', uiInput);

    const uiTextarea = (data: any) => {
        const items = [
            {
                type: 'textarea',
                data, // {label, placeholder, value, style}
            }
        ]
        return {items}
    }
    chatManager.extendEE('ui-textarea', uiTextarea);

    const uiSelect = (data: any) => {
        const items = [
            {
                type: 'select',
                data, // options, value
            }
        ]
        return {items}
    }
    chatManager.extendEE('ui-select', uiSelect);

    const uiDateTime = (data: any = {}) => {
        const items = [
            {
                type: 'datetime',
                data, //
            }
        ]
        return {items}
    }
    chatManager.extendEE('ui-select-datetime', uiDateTime);

    chatManager.extendEE('ui-select-icon', (category?: string, value?: string) => {
        const iconNames = getIconNames(category);
        const options: any = {};
        iconNames.forEach((name: string) => {
            options[name] = {value: name, type: 'icon', data: {text: name}};
        });
        const items = [
            {
                type: 'select',
                data: {
                    options,
                    value: value,
                },
            }
        ]
        return {items}
    });

    interface FormConfig {
        submitBtn?: boolean
    }

    chatManager.extendEE('ui-form', (inputs: any, config: FormConfig = {}) => {
        if (!inputs || inputs === null) inputs = [];
        const formItems = inputs.map((input: any) => {
            return {
                type: 'input',
                data: {
                    placeholder: input.name,
                }
            }
        });
        const form = {
            type: 'form',
            data: {
                items: formItems,
                ...config,
            }
        }
        return {items: [form]};
    });

    const uiQuestion = (saveLocation: string, qtype: string, content: string, requireAnswer: boolean) => {
        const items = [
            {
                type: 'text',
                data: {text: content},
            },
            {
                type: 'button',
                data: {title: 'yes', onClick: `(cset "text" "${saveLocation}" "yes")`}
            },
            {
                type: 'button',
                data: {title: 'no', onClick: `(cset "text" "${saveLocation}" "no")`}
            }
        ]
        return {items, requireAnswer};
    }
    chatManager.extendEE('ui-question', uiQuestion);

    interface QuestionOption {
        label: string;
        value: any;
    }
    interface TimedQuestionData {
        store: string;
        type: string;
        text: string;
        options: any;  // {label: value}
        requireAnswer: 'yes' | 'no' | 'timed';
    }
    const uiTimedQuestion = (data: TimedQuestionData) => {
        const {store, requireAnswer} = data;
        const items = [
            {
                type: 'timedquestion',
                data: {
                    ...data,
                    command: (option: QuestionOption) => {
                        const value = chatManager.toEE(option.value);
                        return `(cset "text" "${store}" ${value})`;
                    }
                },
            },
        ]
        return {items, requireAnswer};
    }
    chatManager.extendEE('ui-question-cron', uiTimedQuestion);

    const uiQrcode = (url: string) => {
        const items = [
            {
                type: 'qrcode',
                data: {url},
            }
        ]
        return {items}
    }
    chatManager.extendEE('ui-qrcode', uiQrcode);

    const uiModal = (contentItems: any, requireAnswer: boolean = false) => {
        const items = [
            {
                type: 'modal',
                data: contentItems,
            }
        ]
        return {items, requireAnswer}
    }
    chatManager.extendEE('ui-modal', uiModal);

    const uiEval = (label: string) => {
        const items = [
            {
                type: 'markdown',
                data: {label},
            }
        ]
        return {items}
    }
    chatManager.extendEE('ui-eval', uiEval);

    const uiList = (...args: any) => {
        const items = [
            {
                type: 'list',
                data: args,
            }
        ]
        return {items}
    }
    chatManager.extendEE('ui-list', uiList);

    const uiCronBuild = (...args: any) => {
        const items = [
            {
                type: 'cronbuild',
                data: args,
            }
        ]
        return {items}
    }
    chatManager.extendEE('ui-cron-build', uiCronBuild);
}
