export const APP_ROOT = process.env.REACT_APP_ROOT;
export const PROTO_ROOT = process.env.REACT_APP_PROTO_ROOT;
export const SERVER_PUBLIC_KEY = process.env.REACT_APP_SERVER_PUBLIC_KEY;
export const SERVER_HOST = process.env.REACT_APP_SERVER_HOST;
export const SERVER_PORT = process.env.REACT_APP_SERVER_PORT;
export const STUN_SERVER = process.env.REACT_APP_STUN_SERVER;
export const TURN_SERVER = process.env.REACT_APP_TURN_SERVER || null;
export const TURN_USER = process.env.REACT_APP_TURN_USER || null;
export const TURN_PASS = process.env.REACT_APP_TURN_PASS || null;
export const LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL;
export const SOCKETIO_HOST = process.env.REACT_APP_SOCKETIO;
