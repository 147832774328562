import React from 'react';
import {useWindowSize} from '@react-hook/window-size/throttled';
import mottos from './mottos';

const LOGO_DIM = 200;
const MIN_DIM = 500;
const FONT_SIZE = 22;

export default function LandingPage (props: any) {
    const [motto, setMotto] = React.useState('');
    const [width, height] = useWindowSize()

    React.useEffect(() => {
        const index = Math.floor(Math.random()*mottos.length);
        const motto = mottos[index];
        setMotto(motto);
    }, []);

    let dim = LOGO_DIM;
    let fontSize = FONT_SIZE;
    if (width < MIN_DIM || height < MIN_DIM) {
        dim = Math.floor(LOGO_DIM * Math.min(width, height) / MIN_DIM);
        fontSize = Math.floor(FONT_SIZE * Math.min(width, height) / MIN_DIM);
    }

    return (
        <div className="logo-div">
            <div className="logo-inner-div" style={{width: dim, height: dim}}>
            <img className="logo-image" src="./logo192.png" alt="ark logo" />
            <p className="logo-text" style={{fontSize}}>{motto}</p>
            </div>
        </div>
    )
}
