import ReactJson from 'react-json-view';

export default function JsonMessage (props: any) {
    let message = props.children;
    const mode = props.mode;
    if (typeof message === 'string') {
        try {
            message = JSON.parse(message);
        } catch (e) {
            return <span>{message}</span>;
        }
    }

    return (
        <div>
            <ReactJson
            src={ message }
            name={null}
            theme={mode === 'dark' ? 'twilight' : 'rjv-default'}
            style={{ fontSize: 16}}
            collapsed={true}
            />
        </div>
    )
}
