import React from 'react';
import Avatar from './Avatar';
import {contacts as contactUtils} from '@ark-us/chat-manager';
import styles from '../styles';
import './Contacts.css';
const {Contact, Conversation} = contactUtils;

type ListItem = typeof Conversation | typeof Contact;

const toCamelCase = (value: string) => value[0].toUpperCase() + value.slice(1);

export interface ContactsProps {
    mode: any;
    showWide: boolean;
    identity: ListItem,
    items: ListItem[],
    selected: ListItem,
    onSelect: (item: ListItem) => void,
}

export interface ItemProps {
    mode: any;
    showWide: boolean;
    item: ListItem,
    selected: ListItem,
    onClick: (items: ListItem) => void,
}

const ContactItemSelected = {
    borderTopLeftRadius: styles.borderRadius,
    borderTopRightRadius: styles.borderRadius,
    borderBottomRightRadius: styles.borderRadius,
    borderBottomLeftRadius: '1px',
}

const Item = (props: ItemProps) => {
    const {mode, selected, item, showWide, onClick} = props;

    let _styles = {}, classes;
    if (selected && item.value.id.value === selected.value.id.value) {
        _styles = {...ContactItemSelected, ...styles[mode].message.user1};
        classes = `ContactItem ContactItemSelected User1`;
    }
    else {
        classes = `ContactItem ContactItemUnselected`;
    }

    // const classes = `ContactItem ${selected && item.value.id.value === selected.value.id.value ? 'ContactItemSelected User1' : 'ContactItemUnselected'}`;
    const name = toCamelCase(item.value.profile.value.attribute) + ' ' + toCamelCase(item.value.profile.value.genus);
    const lastMessage = (item.value.lastBlock ? item.value.lastBlock.value.data?.body : '');
    let displayMessage = '';
    if (lastMessage) {
        displayMessage = lastMessage.slice(0, 30);
        if (lastMessage.length > 30) displayMessage += '..';
    }
    return (
        <div className={classes} style={{display: 'flex', alignItems: 'center', ..._styles}} onClick={onClick}>
            <Avatar
            path={item.value.profile.value.avatar.path}
            color={item.value.profile.value.color[1]}
            complement={item.value.profile.value.avatar.complement}
            size={showWide ? 51 : 30}
            />
            {showWide
                ? <div style={{paddingLeft: 5}}>
                    <p style={{margin: 0}}>{name}</p>
                    <p className="lastMessage" style={{}}>{displayMessage}</p>
                </div>
                : <span></span>
            }

        </div>
    )
}

const Contacts = (props: ContactsProps) => {
    const {items=[], selected, showWide, mode, onSelect} = props;

    const onClick = (_item: ListItem) => {
        onSelect(_item);
    }

    const itemComponents = items.map((item, i) => {
        return <Item key={i} mode={mode} showWide={showWide} item={item} selected={selected} onClick={() => onClick(item)}/>
    })

    return (
        <div className="chat-input" style={{height: '100%'}}>
            <div className="chat-input" style={{height: '100%', overflowY: 'auto'}}>
                {itemComponents}
            </div>
        </div>
    );
};

export default Contacts;
