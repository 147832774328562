import { MultilevelNodes } from './interfaces';
import * as DEFAULT_NODES from './default';
import {itemLabel as _itemLabel} from '../../utils';

export function itemLabel(doctemplate: string) {
    let newlabel = doctemplate;
    const titlendx = doctemplate.indexOf('# ');
    if (titlendx > -1) {
        newlabel = doctemplate.slice(titlendx + 1);
    }
    const newlinendx = newlabel.indexOf('\n');
    if (newlinendx > -1) {
        newlabel = newlabel.slice(0, newlinendx);
    }
    return _itemLabel(newlabel, 30);
}

export function hydrateItem (menutype: string, callback: any, menu: MultilevelNodes[], item: MultilevelNodes, onChange: Function, ee: any, sendMessage: Function, conversationId: string, indexes?: number[]) {
    if (!item) return;
    const {command} = item;

    item.onRemove = () => {

        setItemAfterIndex({
            items: menu,
        }, indexes || item.indexes || [], null);
        onChange(menu);
    }

    if (!item.items && typeof command !== 'undefined') {
        item.onSelected = () => {
            callback(command, item.doctemplate);
        }
        item.onExport = () => {
            console.debug('onExport', item);
            const __imageIcon = ee.transform(item.imageIcon);
            let __doctemplate = ee.transform(item.doctemplate);
            let __command = ee.transform(item.command);

            const addNode = DEFAULT_NODES.ADD_NODE({
                menutype,
                doctemplate: __doctemplate,
                command: __command,
                imageIcon: __imageIcon,
            });

            const doctemplateStr = ee.transform(addNode.doctemplate);
            const commandStr = ee.transform(addNode.command);
            const expr = `(modal "${conversationId}" (quote ${doctemplateStr}) (quote ${commandStr}))`;

            console.debug('onExport', expr);

            sendMessage({
                data: {body: expr},
                type: 'message',
            })
        }
    }
    else if (item.items) {
        // item.onAdd = () => {
        //     console.debug('onAdd', item.indexes, item);
        //     const selectedLevel = ee.transform({indexes: item.indexes, doctemplate: item.doctemplate});

        //     const addNode = DEFAULT_NODES.ADD_NODE({menutype, selectedLevel});
        //     callback(addNode.command, addNode.doctemplate);
        // }
    }
}

export function hydrateMenu (menutype:string, callback: any, menu: MultilevelNodes[], onChange: Function, ee: any, sendMessage: Function, conversationId: string) {
    menu.forEach((item: MultilevelNodes, i: number) => {
        if (!item) return;

        hydrateItem(menutype, callback, menu, item, onChange, ee, sendMessage, conversationId, item.indexes?.concat([i]));

        if (item.items) {
            hydrateMenu(menutype, callback, item.items, onChange, ee, sendMessage, conversationId);
        }
    });
}

export function menuset (
    selectCallback: Function,
    onChange: Function,
    ee: any,
    sendMessage: Function,
    conversationId: string,
    menuItems: MultilevelNodes[],
    menutype: string,
    nodetype: string,
    level: any,
    afterLeaf: any,
    doctemplate: string,
    imageIcon: string,
    command?: string,
) {
    let indexes = [...level.indexes];
    if (afterLeaf && afterLeaf.indexes) {
        indexes = [...afterLeaf.indexes];
        indexes[indexes.length - 1] += 1;
    }
    else {
        indexes.push(0);
    }

    let item: MultilevelNodes = {
        doctemplate,
        indexes,
    };
    if (nodetype === 'level') {
        item.imageIcon = imageIcon || 'menu';
        item.items = [];
    }
    else {
        item.command = command;
        item.imageIcon = imageIcon || 'lambda';
    }

    hydrateItem(menutype, selectCallback, menuItems, item, onChange, ee, sendMessage, conversationId);

    // set by reference
    setItemAfterIndex({
        items: menuItems,
    }, indexes, item);

    return menuItems;
}

export function getItemAtIndex (item: MultilevelNodes, indexes: number[]): any {
    if (indexes.length === 0) return item;
    if (!item.items) return item;
    if (item.items.length === 0) return null;

    const index = indexes.shift();
    if (typeof index === 'undefined') return item;
    return getItemAtIndex(item.items[index], indexes);
}

export function setItemAfterIndex (node: MultilevelNodes, indexes: number[], item: MultilevelNodes | null): any {
    if (indexes.length === 0 && item) {
        node = item;
    }
    else if (!node.items) {
        node.items = [];
    }
    if (node.items) {
        const index = indexes.shift();
        if (typeof index === 'undefined') {
            if (item) node = item;
        }
        else if (indexes.length === 0) {
            if (item) node.items.splice(index, 0, item);
            else node.items.splice(index, 1);
        }
        else {
            setItemAfterIndex(node.items[index], indexes, item);
        }
    }
}

export function flattenMenu (node: MultilevelNodes, indexes: number[], levels: boolean = true, leafs: boolean = true, path: string, setIndexes = false) {
    if (setIndexes) {
        node.indexes = [...indexes];
    }
    const _path = node.path = path + '/' + itemLabel(node.doctemplate);
    if (!node.items && leafs) return [node];

    let nodes: any = [];
    if (levels && node.items) nodes = [node];

    const subitems: MultilevelNodes[] = (node.items || []).map((item: MultilevelNodes, i: number) => {
        const _indexes = indexes.concat([i]);
        return flattenMenu(item, _indexes, levels, leafs, _path, setIndexes);
    }).reduce((accum: any, value: any) => accum.concat(value), []);
    nodes = nodes.concat(subitems);
    return nodes;
}


export function defaultMenu () {
    const menuItems: MultilevelNodes[] = [];
    const addNode = DEFAULT_NODES.ADD_NODE({menutype: 'conversation'});
    menuItems[0] = addNode;
    menuItems[1] = {
        doctemplate: 'account',
        imageIcon: 'user',
        items: [
            {...DEFAULT_NODES.IDENTITY_CREATE},
            {...DEFAULT_NODES.ADD_CONTACT},
            {...DEFAULT_NODES.LINK_DEVICE},
            {...DEFAULT_NODES.IDENTITY_SELECT},
            {...DEFAULT_NODES.RESET_ACCOUNT},
        ]
    }
    menuItems[2] = {...DEFAULT_NODES.THEME_CHANGE};
    menuItems[3] = {...DEFAULT_NODES.RESET_MENU};
    menuItems[4] = {...DEFAULT_NODES.QUESTION};
    // menuItems[5] = {...DEFAULT_NODES.QUESTION_CRON};
    // menuItems[6] = {...DEFAULT_NODES.CRONJOB};

    // return menuItems.concat(menuItems).concat(menuItems).concat(menuItems);

    return menuItems;
}

export function systemMenu () {
    const menuItems: MultilevelNodes[] = [];
    menuItems[0] = {
        doctemplate: 'account',
        imageIcon: 'user',
        items: [
            {...DEFAULT_NODES.IDENTITY_CREATE},
            {...DEFAULT_NODES.RESET_ACCOUNT},
        ]
    }
    menuItems[1] = {...DEFAULT_NODES.RESET_MENU};
    return menuItems;
}

export function messageMenu() {
    const menuItems: MultilevelNodes[] = [];
    const addNode = DEFAULT_NODES.ADD_NODE({menutype: 'message'});
    menuItems[0] = addNode;
    menuItems[1] = {...DEFAULT_NODES.REPLY_MESSAGE};
    return menuItems;
}
