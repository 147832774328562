import React from 'react';
import './App.css';
// import './signalSetup';
import {ChatManager} from '@ark-us/chat-manager';
// import {init, register, deactivate} from '@ark-us/signal-protocol';
// import SaltyRTCTransport from '@ark-us/saltyrtc-transport';
// import Libp2pTransport from '@ark-us/libp2p-transport';
import SocketioTransport from '@ark-us/socketio-transport';
import { InfoCircleOutlined } from '@ant-design/icons';
import ChatView from './views/ChatView';
import InfoComponent from './views/InfoComponent';
import {watchTheme} from './utils';
import styles from './styles';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import * as CONFIG from './config';

import darkVars from './dark.json';
import lightVars from './light.json';

const themeMap = {
    dark: darkVars,
    light: lightVars,
}
// eslint-disable-next-line
const SALTY_CONFIG = {
  server: {
    PUBLIC_KEY: CONFIG.SERVER_PUBLIC_KEY,
    HOST: CONFIG.SERVER_HOST,
    PORT: CONFIG.SERVER_PORT,
  },
  STUN_SERVER: CONFIG.STUN_SERVER,
  TURN_SERVER: CONFIG.TURN_SERVER,
  TURN_USER: CONFIG.TURN_USER,
  TURN_PASS: CONFIG.TURN_PASS,
  LOG_LEVEL: CONFIG.LOG_LEVEL,
}
// eslint-disable-next-line
const LIBP2P_CONFIG = {
  LISTENERS: [
    '/dns4/wrtc-star1.par.dwebops.pub/tcp/443/wss/p2p-webrtc-star',
    '/dns4/wrtc-star2.sjc.dwebops.pub/tcp/443/wss/p2p-webrtc-star'
  ],
  BOOTSTRAP: [
    // '/ip4/127.0.0.1/tcp/63786/ws/p2p/QmWjz6xb8v9K4KnYEwP5Yk75k5mMBCehzWFLCvvQpYxF3d',
    '/dnsaddr/bootstrap.libp2p.io/p2p/QmbLHAnMoJPWSCR5Zhtx6BHJX9KiKNN6tpvbUcqanj75Nb',
    '/dnsaddr/bootstrap.libp2p.io/p2p/QmNnooDu7bfjPFoTZYxMNLWUQJyrVwtbZg5gBMjTezGAJN'
  ],
  TURN_SERVERS: [
    {
      "urls": ["stun.l.google.com:19302"],
      "username": '',
      "credential": '',
    },
  ]
}

const chatManager = new ChatManager(localStorage);
// @ts-ignore
window.ark = chatManager;

// const signalAdapter = {
//   init,
//   register,
//   deactivate,
//   createIdentity: () => {return {}},
//   sendMessage: (contact: any, data: any) => {
//     // @ts-ignore
//     if (!window.Signal.messageSender) {
//       return new Promise((resolve, reject) => {
//         resolve({errors: ['MessageSender not initialized']});
//       })
//     }
//     if (data.type === 'message')
//       // @ts-ignore
//       return window.Signal.messageSender.sendMessageToIdentifier(data.identifier, data.message.body, undefined, undefined, undefined, undefined, undefined, undefined, data.timestamp, undefined)
//     if (data.type === 'typing')
//     // @ts-ignore
//       return window.Signal.messageSender.sendTypingMessage(data);
//   },
//   getEventTarget: () => {
//     // @ts-ignore
//     return window.Signal.appMessageReceiver;
//   },
//   async buildQuery(method: string, callback: any) {
//     let queryObj: any = {};

//     switch(method) {
//         case 'connect':
//             queryObj.number = localStorage.getItem('number_id')?.split('.')[0]
//             queryObj.name = ''
//             break;
//         case 'link':
//             break;
//         default:
//             break;
//     }
//     return queryObj;
//   },
//   buildContactSync (identityTransportObject: any = {}) {
//     return {
//         identifier: {
//             number: identityTransportObject.number,
//             uuid: identityTransportObject.uuid,
//         }
//     }
//   }
// }

let activatedTransports: any = localStorage.getItem('activatedTransports');
try {
  activatedTransports = JSON.parse(activatedTransports);
} catch (e) {
  activatedTransports = null;
}
if (!activatedTransports) {
  activatedTransports = {saltyrtc: false, signal: false, libp2p: false, socketio: true};
  localStorage.setItem('activatedTransports', JSON.stringify(activatedTransports));
}

// chatManager.transportManager.registerTransport(
//   'saltyrtc',
//   new SaltyRTCTransport(SALTY_CONFIG),
//   1,
// );

// chatManager.transportManager.registerTransport(
//   'libp2p',
//   new Libp2pTransport(LIBP2P_CONFIG),
//   2,
// );

chatManager.transportManager.registerTransport(
  'socketio',
  new SocketioTransport({
    server: CONFIG.SOCKETIO_HOST,
  }),
  3,
);
// chatManager.transportManager.registerTransport('signal', signalAdapter, 4);

type Mode = 'light' | 'dark';
type ThemePreference = 'light' | 'dark' | 'system';
const themePreference: string | null = localStorage.getItem('arktheme');

const getSystemTheme = () => window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches ? 'light' : 'dark';

export default function App() {
  let _mode: Mode = getSystemTheme();
  if (themePreference && themePreference === 'light') _mode = 'light';
  else if (themePreference && themePreference === 'dark') _mode = 'dark';

  const [mode, setMode] = React.useState<Mode>(_mode);
  const [transports, setTransports] = React.useState(activatedTransports);
  const [conversations, setConversations] = React.useState([]);
  const [showInfo, setShowInfo] = React.useState(false);

  localStorage.setItem('arkmode', mode); // used by components

  // @ts-ignore
  window.less.modifyVars(themeMap[mode]);

  React.useEffect(() => {
    const setup = async () => {
      const _transports = Object.keys(transports).filter(v => transports[v]);
      await chatManager.init(_transports);
      setConversations(chatManager.conversations);
    }
    setup();

    watchTheme((v: Mode) => {
      const _preference = localStorage.getItem('arktheme');
      if (_preference && _preference === 'system') setMode(v);
    });

    chatManager.extendEE('theme-get', () => {
      return localStorage.getItem('arktheme') || 'system';
    });

    chatManager.extendEE('theme-switch', (newmode: ThemePreference) => {
      if (!newmode || (newmode !== 'light' && newmode !== 'dark' && newmode !== 'system')) return;
      localStorage.setItem('arktheme', newmode);
      if (newmode === 'light' || newmode === 'dark') {
        setMode(newmode);
      }
      if (newmode === 'system') {
        setMode(getSystemTheme());
      }
    });

    return function cleanup() {
      console.debug('DISCONNECT');
      chatManager.transportManager.disconnect();
    }
  // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line
  const toggleSignal = () => {
    const newtransports = {...transports, signal: !transports.signal}
    if (newtransports.signal) chatManager.transportManager.activateTransport({transports: ['signal']})
    else chatManager.transportManager.deactivateTransport({transports: ['signal']})
    setTransports(newtransports);
    localStorage.setItem('activatedTransports', JSON.stringify(newtransports));
  }
  // eslint-disable-next-line
  const toggleSalty = () => {
    const newtransports = {...transports, saltyrtc: !transports.saltyrtc}
    if (newtransports.saltyrtc) chatManager.transportManager.activateTransport({transports: ['saltyrtc']})
    else chatManager.transportManager.deactivateTransport({transports: ['saltyrtc']})
    setTransports(newtransports);
    localStorage.setItem('activatedTransports', JSON.stringify(newtransports));
  }
  // eslint-disable-next-line
  const toggleLibp2p = () => {
    const newtransports = {...transports, libp2p: !transports.libp2p}
    if (newtransports.libp2p) chatManager.transportManager.activateTransport({transports: ['libp2p']})
    else chatManager.transportManager.deactivateTransport({transports: ['libp2p']})
    setTransports(newtransports);
    localStorage.setItem('activatedTransports', JSON.stringify(newtransports));
  }

  return (
    <div className="App tiledBackground" style={{...styles[mode].common}}>
    <Router basename={CONFIG.APP_ROOT}>
        <nav className="AppHeader">
            {/* <Link to="/"><InfoCircleOutlined className="App-link" style={{paddingLeft: 5, fontSize: 20}}/></Link> */}

            <InfoCircleOutlined className="App-link" style={{paddingLeft: 5, fontSize: 20}} onClick={() => setShowInfo(!showInfo)}/>

            {/* <button style={{background: transports.signal ? '#243a4a' : '#292626'}} onClick={toggleSignal}>signal</button>
            <button style={{background: transports.saltyrtc ? '#243a4a' : '#292626'}} onClick={toggleSalty}>saltyrtc</button>
            <button style={{background: transports.libp2p ? '#243a4a' : '#292626'}} onClick={toggleLibp2p}>libp2p</button> */}
        </nav>
        <Switch>
          <Route >
            <ChatView mode={mode} chatManager={chatManager} conversations={conversations}/>
            <InfoComponent isVisible={showInfo} onClose={() => setShowInfo(false)} />
          </Route>
        </Switch>
    </Router>
    </div>
  );
}
